import ApiService from "./api.service";

const ProjectService = {

    async getUnfinishedProjects() {
        return ApiService.get('/api/unfinished-projects')
    },
    async getUnfinsihedProjectTasks(projectId) {
        return ApiService.get('/api/get-unfinished-project-tasks/' + projectId)
    },
    async getAssignedToProjects() {
        return ApiService.get('api/get-assigned-to-projects')
    },
    async setProjectStatus(projectId, projectStatus) {
        return ApiService.post('/api/project/' + projectId + '/set-status', projectStatus)
    },
    async getProjectDetails(projectId) {
        return ApiService.get('api/project/' + projectId)
    }
}

export default ProjectService