<template>
  <base-layout
    v-if="isDataLoaded"
    :pageTitle="
      translate('Intervention') +
      ' ' +
      translate('Details') +
      ': ' +
      currentIntervention.title
    "
    :pageDefaultBackLink="backDefaultUrl"
    :showFooter="true"
  >
    <template v-slot:actions-end>
      <ion-button @click="refreshComponent">
        <ion-icon :icon="refresh" slot="icon-only"> </ion-icon>
      </ion-button>
      <ion-button @click="openPopover">
        <ion-icon
          slot="icon-only"
          :ios="ellipsisHorizontal"
          :md="ellipsisVertical"
        ></ion-icon>
      </ion-button>
    </template>
    <info-tab v-if="showHours" :interventionId="id"></info-tab>
    <task-tab
      v-if="showTask"
      :tasks="currentIntervention.tasks"
      :projectTasks="currentIntervention.project.tasks"
      :interventionId="id"
    ></task-tab>
    <photo-tab
      v-if="showPhotos"
      :photos="currentIntervention.photos"
      :interventionId="id"
    ></photo-tab>
    <docs-tab
      v-if="showDocuments"
      :docs="currentIntervention.documents"
      :projectDocs="currentIntervention.project.documents"
    ></docs-tab>
    <product-tab
      v-if="showProducts"
      :products="usedProducts"
      :projectProducts="estimatedProducts"
      :interventionId="id"
    ></product-tab>
    <template v-slot:footerContent>
      <ion-toolbar class="footer">
        <ion-segment @ionChange="tabChange($event)" value="hours">
          <ion-segment-button value="hours">
            <ion-icon :icon="location"></ion-icon>
            <ion-label>{{ translate("Hours") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="tasks">
            <ion-icon :icon="clipboard"></ion-icon>
            <ion-label>{{ translate("Tasks") }}</ion-label>
            <ion-badge class="badge" v-if="isDataLoaded" color="warning">{{
              currentIntervention.project.tasks.length +
              interventionPlannedTasksQtty
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button value="photos">
            <ion-icon :icon="image"></ion-icon>
            <ion-label>{{ translate("Photos") }}</ion-label>
            <ion-badge class="badge" v-if="isDataLoaded" color="warning">{{
              currentIntervention.photos.length
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button value="products">
            <ion-icon :icon="hammer"></ion-icon>
            <ion-label>{{ translate("Products") }}</ion-label>
            <ion-badge class="badge" v-if="isDataLoaded" color="warning">{{
              usedProducts.length
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button value="documents">
            <ion-icon :icon="document"></ion-icon>
            <ion-label>{{ translate("Docs") }}</ion-label>
            <ion-badge class="badge" v-if="isDataLoaded" color="warning">{{
              documentsCount
            }}</ion-badge>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
  </base-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  IonIcon,
  IonLabel,
  IonBadge,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonButton,
  popoverController,
} from "@ionic/vue";
import {
  construct,
  image,
  clipboard,
  refresh,
  location,
  document,
  ellipsisHorizontal,
  ellipsisVertical,
  hammer,
} from "ionicons/icons";
import BaseLayout from "@/components/base/BaseLayout.vue";
import InfoTab from "./InfoTab";
import TaskTab from "./TaskTab";
import PhotoTab from "./PhotoTab";
import DocsTab from "./DocsTab.vue";
import ProductTab from "./ProductTab.vue";
import { watchEffect } from "vue";
import Popover from "./popover.vue";
import UserService from "@/services/user.service";

export default {
  name: "InterventionTabs",
  props: ["id"],
  components: {
    IonIcon,
    IonLabel,
    IonBadge,
    IonToolbar,
    BaseLayout,
    InfoTab,
    TaskTab,
    PhotoTab,
    IonSegment,
    IonSegmentButton,
    IonButton,
    DocsTab,
    ProductTab,
  },
  data() {
    return {
      construct,
      image,
      clipboard,
      refresh,
      location,
      document,
      showHours: true,
      showTask: false,
      showPhotos: false,
      showDocuments: false,
      showProducts: false,
      ellipsisHorizontal,
      ellipsisVertical,
      hammer,
      userDetails: {},
    };
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    refreshComponent() {
      this.fetchIntervention(this.id).catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
    },
    tabChange(event) {
      const tabNameClicked = event.detail.value;
      this.showHours =
        this.showTask =
        this.showPhotos =
        this.showDocuments =
        this.showProducts =
          false;
      switch (tabNameClicked) {
        case "tasks":
          this.showTask = true;
          break;
        case "photos":
          this.showPhotos = true;
          break;
        case "hours":
          this.showHours = true;
          break;
        case "documents":
          this.showDocuments = true;
          break;
        case "products":
          this.showProducts = true;
          break;
        default:
          this.showTask = true;
          break;
      }
    },
    async openPopover(event) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        event: event,
        componentProps: {
          interventionId: this.currentIntervention.id,
        },
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
  },
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
    ...mapGetters("project", ["allProjectTasks"]),
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Interventions",
      });
      return href;
    },
    isDataLoaded() {
      return Object.keys(this.currentIntervention).length !== 0;
    },
    documentsCount() {
      return (
        this.currentIntervention.documents.filter(
          (doc) =>
            doc.access_permission !== "administrator" ||
            this.userDetails?.role?.toLowerCase().includes("direction") ||
            this.userDetails?.role?.toLowerCase().includes("administrator") ||
            this.userDetails?.role?.toLowerCase().includes("administratif")
        ).length +
        this.currentIntervention.project.documents.filter(
          (doc) =>
            doc.access_permission !== "administrator" ||
            this.userDetails?.role?.toLowerCase().includes("direction") ||
            this.userDetails?.role?.toLowerCase().includes("administrator") ||
            this.userDetails?.role?.toLowerCase().includes("administratif")
        ).length
      );
    },
    estimatedProducts() {
      return this.currentIntervention.project.product_transactions.filter(
        function (el) {
          return el.type == "lock";
        }
      );
    },
    usedProducts() {
      return this.currentIntervention.product_transactions.filter(function (
        el
      ) {
        return el.type == "out";
      });
    },
    interventionPlannedTasksQtty() {
      return this.currentIntervention.tasks.filter((task) => {
        return task.status == "planned";
      }).length;
    },
  },
  created() {
    watchEffect(async () => {
      await this.fetchIntervention(this.id).catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
    });
    UserService.getInfo(1)
      .then((response) => {
        this.userDetails = response.data;
      })
      .catch((error) =>
        this.$router.push({ name: "ErrorDisplay", params: { error } })
      );
  },
};
</script>

<style scoped>
.footer {
  --background: #ffffff;
  --color: black;
}
.badge {
  position: absolute;
  right: 1px;
  top: 5px;
}
</style>
