<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center"
        ><strong>{{ translate("Location") }}: </strong>
        {{ locationName != null ? translate(locationName) : "" }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{
          translate("Close")
        }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <g-map
      :disableUI="false"
      :marker="point != null ? point : ''"
      :title="locationName != null ? translate(locationName) : ''"
    ></g-map>
  </ion-content>
</template>

<script>
import GMap from "@/components/geo/GMap";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MapModal",
  props: {
    locationName: {
      type: String,
    },
    point: {
      type: Array,
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    GMap,
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
  },
});
</script>

<style>
</style>