import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home.vue'
import SignIn from "../views/SignIn.vue";
import { TokenService } from '../services/token.service';
import Interventions from '../views/interventions/Interventions'
import ErrorDisplay from '../views/ErrorDisplay'
import InterventionTabs from '../views/interventions/details/Tabs.vue'
import AddEditTask from '../views/tasks/AddEdit.vue'
import AddEditPhoto from '../views/photos/AddEdit.vue'
import Calendar from "../views/calendar/Calendar.vue"
import AddEditIntervention from '../views/interventions/AddEdit.vue'
import AddEditProduct from '../views/products/AddEdit.vue'
import Profile from '../views/profile/Profile.vue'
import EditProfile from '../views/profile/Edit.vue'
import TagTab from '../views/interventions/details/TagTab.vue'
import AddEditTag from '../views/tags/AddEdit.vue'
import TaskHistory from '../views/interventions/details/TaskHistoryTab.vue'
import Projects from '../views/projects/Projects.vue'
import ProjectDetails from '../views/projects/ProjectDetails.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      public: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      public: false,
      onlyWhenLoggedOut: false
    }
  },
  {
    path: '/login',
    component: SignIn,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    // component: Project,
    redirect: '/home',
    meta: {
      public: false,
    }
  },
  {
    path: '/interventions',
    name: 'Interventions',
    component: Interventions,
    // redirect: '/home',
    meta: {
      public: false,
    }
  },
  {
    path: '/intervention/details/:id/',
    name: 'InterventionTabsRoute',
    props: true,
    component: InterventionTabs,
    meta: {
      public: false,
    },
  },
  {
    path: '/intervention/details/:id/tags',
    name: 'TagTabRoute',
    props: true,
    component: TagTab,
    meta: {
      public: false,
    },
  },
  {
    path: '/intervention/details/:id/taskhistory',
    name: 'TaskHistoryTabRoute',
    props: true,
    component: TaskHistory,
    meta: {
      public: false,
    },
  },
  {
    path: '/tag/create-edit',
    name: 'TagCreateEdit',
    component: AddEditTag,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/task/create-edit',
    name: 'TaskCreateEdit',
    component: AddEditTask,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/photo/create-edit',
    name: 'PhotoCreateEdit',
    component: AddEditPhoto,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/planning',
    name: 'Planning',
    component: Calendar,
    meta: {
      public: false,
    }
  },
  {
    path: '/error/:error',
    name: 'ErrorDisplay',
    props: true,
    component: ErrorDisplay
  },
  {
    path: '/intervention/create-edit',
    name: 'InterventionCreateEdit',
    component: AddEditIntervention,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/product/create-edit',
    name: 'ProductCreateEdit',
    component: AddEditProduct,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    props: true,
    meta: {
      public: false
    }
  },
  {
    path: '/profile/edit',
    name: 'ProfileEdit',
    component: EditProfile,
    meta: {
      public: false
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      public: false
    }
  },
  {
    path: '/project/details/:id',
    name: 'ProjectDetails',
    props: true,
    component: ProjectDetails,
    meta: {
      public: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/home");
  }

  next();
});

export default router
