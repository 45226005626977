<template>
  <ion-list>
    <ion-searchbar
      animated
      v-model="search"
      :placeholder="translate('Filter') + ' ' + translate('Products')"
      show-cancel-button="focus"
      debounce="200"
    ></ion-searchbar>
    <ion-item-divider :sticky="true" color="tertiary">
      <ion-label>
        <strong>{{
          translate("Project") +
          " " +
          translate("Estimated") +
          " " +
          translate("Products")
        }}</strong>
      </ion-label>
    </ion-item-divider>
    <ion-item color="light">
      <ion-label class="ion-text-start"> {{ translate("Locked") }} </ion-label>
      <ion-label class="ion-text-start"> {{ translate("Product") }} </ion-label>
      <ion-label class="ion-text-start"> {{ translate("Usable") }} </ion-label>
      <ion-label class="ion-text-start">
        {{ translate("Released") }}
      </ion-label>
    </ion-item>
    <product-list-item
      :projectProduct="true"
      v-for="(product, index) in filteredProjectProducts"
      :key="index"
      :product="product"
      :available="product.available"
    >
    </product-list-item>
    <ion-item-divider :sticky="true" color="warning">
      <ion-label
        ><strong>
          {{ translate("Used") + " " + translate("Products") }}
        </strong>
      </ion-label>
    </ion-item-divider>
    <ion-item color="light">
      <ion-label class="ion-text-start"> {{ translate("Used") }} </ion-label>
      <ion-label class="ion-text-start"> {{ translate("Product") }} </ion-label>
    </ion-item>
    <product-list-item
      :projectProduct="false"
      v-for="(product, index) in filteredProducts"
      :key="index"
      :product="product"
      :quantityColor="quantityColor(product)"
    >
    </product-list-item>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonItemDivider,
  IonLabel,
  IonItem,
  IonSearchbar,
} from "@ionic/vue";
import ProductListItem from "./ListItem";
import ProductService from "@/services/product.service";
export default {
  name: "ProductList",
  components: {
    IonList,
    ProductListItem,
    IonItemDivider,
    IonLabel,
    IonItem,
    IonSearchbar,
  },
  props: {
    products: Array,
    projectProducts: Array,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    quantityColor(product) {
      let color = "black";
      this.projectProducts.forEach(
        (projectProduct) => {
          if (projectProduct.product_id === product.product_id) {
            if (projectProduct.qty > product.qty) {
              color = "#ff3300"; //red
            } else if (projectProduct.qty < product.qty) {
              color = "#0066ff"; //blue
            } else {
              color = "#808080"; //grey
            }
          }
        },
        color,
        product
      );
      return color;
    },
  },
  computed: {
    filteredProjectProducts() {
      return this.projectProducts.filter((product) => {
        return (
          product.product.name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    filteredProducts() {
      return this.products.filter((product) => {
        return (
          product.product.name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  created() {
    this.projectProducts.forEach((element) => {
      ProductService.getReleasedQuantity(
        element.product_id,
        element.project_id
      ).then((response) => {
        element.release = response.data;
      });

      ProductService.getTotalUsedMaterial(
        element.product_id,
        element.project_id
      ).then((response) => {
        element.available = element.qty - response.data;
      });
    });
  },
};
</script>

<style>
</style>