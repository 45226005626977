<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label>{{ translate("Project") }}: </ion-label>
        <ion-label>{{ projectTitle }}</ion-label>
        <!-- <ion-label @click="openModal" color="primary">{{
          translate("Select")
        }}</ion-label> -->
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Title") }}: </ion-label>
        <ion-input
          type="text"
          v-model="GStore.intervention.title"
          :placeholder="translate('Title')"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Start") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="2020"
          minute-values="0,15,30,45"
          v-model="GStore.intervention.start"
        ></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("End") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="1997"
          minute-values="0,15,30,45"
          v-model="GStore.intervention.end"
        ></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label
          >{{
            translate("Description") + " " + translate("Intervention")
          }}:</ion-label
        >
        <ion-textarea
          v-model="GStore.intervention.technician_intervention_description"
        ></ion-textarea>
      </ion-item>
      <!-- <ion-item v-if="GStore.intervention.confirmed">
        <ion-label>{{ translate("Departure") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="1997"
          v-model="GStore.intervention.departure"
        ></ion-datetime>
      </ion-item>
      <ion-item v-if="GStore.intervention.confirmed">
        <ion-label>{{ translate("On site") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="1997"
          v-model="GStore.intervention.on_site"
        ></ion-datetime>
      </ion-item>
      <ion-item v-if="GStore.intervention.confirmed">
        <ion-label>{{ translate("Finished") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="1997"
          v-model="GStore.intervention.finished"
        ></ion-datetime>
      </ion-item>
      <ion-item v-if="GStore.intervention.confirmed">
        <ion-label>{{ translate("Arrival") }}: </ion-label>
        <ion-datetime
          display-format="YYYY MM D H:mm"
          min="1997"
          v-model="GStore.intervention.arrival"
        ></ion-datetime>
      </ion-item> -->
    </ion-list>
    <ion-button @click="handleCancel">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InterventionService from "@/services/intervention.service";
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController,
  IonDatetime,
  modalController,
  IonTextarea,
} from "@ionic/vue";
import SearchableSelect from "@/components/searchableSelect/searchableSelectList.vue";
export default {
  name: "AddEditInterventionForm",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
  },
  components: {
    IonLabel,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonDatetime,
    IonTextarea,
  },
  data() {
    return {
      formdata: {
        id: "",
        title: "",
        start: "",
        end: "",
      },
    };
  },
  inject: ["GStore"],
  computed: {
    ...mapGetters("project", ["unfinishedProjects"]),
    showProjects() {
      return this.formType === "Update" ? true : false;
    },
    projectTitle() {
      let title = "";
      this.unfinishedProjects.forEach((element) => {
        if (this.GStore.intervention.project_id == element.id)
          title = element.title;
      });
      return title;
    },
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    ...mapActions("project", ["fetchUnfinishedProjects"]),
    async submitForm() {
      const datesValidated = await this.validateDates();
      if (!datesValidated) {
        return;
      }
      let serviceFunctionName =
        this.formType === "Create"
          ? "createIntervention"
          : "updateIntervention";
      await InterventionService[serviceFunctionName](this.GStore.intervention)
        .then(async () => {
          const action = this.formType === "Create" ? "created" : "updated";
          const toast = await toastController.create({
            message:
              this.translate("Intervention") + " was " + this.translate(action),
            position: "bottom",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          if (this.formType === "Update") {
            this.fetchIntervention(this.GStore.intervention.id);
            this.$router.replace({
              name: "InterventionTabsRoute",
              params: {
                id: this.GStore.intervention.id,
                key: Math.floor(Math.random() * (10000 - 1 + 1) + 1),
              },
            });
          } else {
            this.$router.replace({
              name: "Interventions",
            });
          }
          this.GStore.intervention = {};
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data.message),
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
    async validateDates() {
      const start = new Date(this.GStore.intervention.start);
      const end = new Date(this.GStore.intervention.end);
      let message = "";
      let now = new Date();
      now.setHours(now.getHours() - 2);
      if (
        start.getDate() != end.getDate() ||
        start.getYear() != end.getYear() ||
        start.getMonth() != end.getMonth()
      ) {
        message = this.translate(
          "The start date and end date must be the same"
        );
      } else if (start >= end) {
        message = this.translate("The end date must be after the start date");
      } else if (start < now && this.formType === "Create") {
        message = this.translate(
          "You can only create an intervention starting 2 hours in the past max"
        );
      } else {
        return true;
      }
      const toast = await toastController.create({
        message: message,
        position: "botton",
        duration: 3000,
        color: "danger",
      });
      await toast.present();
      return false;
    },
    handleCancel() {
      this.GStore.intervention = {};
      this.$router.go(-1);
    },
    async openModal() {
      const modal = await modalController.create({
        component: SearchableSelect,
        componentProps: {
          selectType: "projects",
          items: this.unfinishedProjects,
        },
      });
      await modal.present();
      await modal.onDidDismiss().then((data) => {
        this.GStore.intervention.project_id = data.data;
      });
    },
  },
  async created() {
    await this.fetchUnfinishedProjects().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
  },
};
</script>

<style>
</style>
