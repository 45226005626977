<template>
  <base-layout :pageTitle="pageTitle" :pageDefaultBackLink="backDefaultUrl">
    <edit-profile-form></edit-profile-form>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/base/BaseLayout.vue";
import EditProfileForm from "@/components/profile/EditForm.vue";
export default {
  name: "EditProfile",
  components: {
    BaseLayout,
    EditProfileForm,
  },
  inject: ["GStore"],
  computed: {
    pageTitle() {
      return (
        this.translate("Update") +
        " " +
        this.GStore.profile.name +
        " " +
        this.translate("Profile")
      );
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Profile",
        params: { key: Math.floor(Math.random() * 10) },
      });
      return href;
    },
  },
};
</script>

<style>
</style>