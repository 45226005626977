<template>
  <ion-card
    :class="cardClass"
    :router-link="{
      name: 'InterventionTabsRoute',
      params: { id: intervention.id },
    }"
  >
    <ion-card-title>
      <strong>{{ intervention.title + " (" + intervention.id + ")" }}</strong>
    </ion-card-title>
    <ion-card-subtitle color="dark">
      <p>{{ interventionDate }}</p>
    </ion-card-subtitle>
    <ion-card-content>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="location" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          <a
            :href="
              'https://maps.google.com/?q=' +
                intervention.project.street_name +
                ',' +
                intervention.project.postal_code
            "
            @click.stop="printTelephone()"
          >
            <h2>{{ intervention.project.street_name }}</h2>
            <p>{{ intervention.project.postal_code }}</p>
          </a>
          <p>
            {{
              intervention.project.floor != null
                ? translate("Floor") + " : " + intervention.project.floor
                : ""
            }}
          </p>
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="intervention.project.tenant != null">
        <ion-icon :icon="person" color="secondary" size="large"></ion-icon>
        <ion-label class="ion-text-wrap ion-padding">
          {{ translate("Tenant") + ": " + intervention.project.tenant }}
        </ion-label>
      </ion-item>
      <ion-item
        class="item-card"
        v-if="intervention.project.tenant_phone_one != null"
      >
        <ion-icon :icon="call" color="tertiary" size="large"></ion-icon>
        <ion-label class="ion-padding">
          <a
            v-if="intervention.project.tenant_phone_one != null"
            :href="'tel:' + intervention.project.tenant_phone_one"
            @click.stop="printTelephone()"
          >
            {{ intervention.project.tenant_phone_one }}
          </a>
        </ion-label>
      </ion-item>
      <ion-item
        class="item-card"
        background="warning"
        v-if="intervention.project.contact != null"
      >
        <ion-icon :icon="person" color="danger" size="large"></ion-icon>
        <ion-label class="ion-text-wrap ion-padding">
          {{ translate("Contact") + ": " + intervention.project.contact }}
        </ion-label>
      </ion-item>
      <ion-item
        class="item-card"
        background="warning"
        v-if="intervention.project.contact_phone != null"
      >
        <ion-icon :icon="call" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          <a
            v-if="intervention.project.contact_phone != null"
            :href="'tel:' + intervention.project.contact_phone"
            @click.stop="printTelephone()"
          >
            {{ intervention.project.contact_phone }}
          </a>
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="isDataLoaded">
        <ion-list>
          <ion-list-header>
            <ion-icon :icon="construct" color="warning" size="large"></ion-icon>
            <ion-label class="ion-padding">
              {{ translate("Tasks") }}:
            </ion-label>
          </ion-list-header>
          <ion-item
            v-for="(task, index) in intervention.project.tasks"
            :key="index"
          >
            <ion-label class="ion-text-wrap" v-if="task.length > 0">{{
              taskCategories.find(
                (category) => category.id === task.task_category_id
              ).name
            }}</ion-label>
            <!-- <ion-label>{{task.task_category_id}}</ion-label> -->
          </ion-item>
        </ion-list>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="construct" color="danger" size="large"></ion-icon>
        <ion-label class="ion-text-wrap ion-padding">
          {{ intervention.project.task_description }}
        </ion-label>
      </ion-item>
      <ion-item
        class="item-card"
        background="warning"
        v-if="intervention.technician_intervention_description != null"
      >
        <ion-icon :icon="listOutline" color="primary" size="large"></ion-icon>
        <ion-label class="ion-text-wrap ion-padding">
          {{
            translate("Technician Intervention Description") +
              ": " +
              intervention.technician_intervention_description
          }}
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
  IonIcon,
  IonList,
  IonListHeader,
} from "@ionic/vue";
import { location, person, call, construct, listOutline } from "ionicons/icons";
import { mapState } from "vuex";
export default {
  props: ["intervention"],
  components: {
    IonLabel,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonCard,
    IonItem,
    IonIcon,
    IonList,
    IonListHeader,
  },
  data() {
    return {
      location,
      person,
      call,
      construct,
      listOutline,
      interventionDate: "",
    };
  },
  computed: {
    ...mapState("task", ["taskCategories"]),
    isDataLoaded() {
      return this.intervention.project.tasks.length !== 0;
    },
    cardClass() {
      if (
        this.intervention.arrival !== null ||
        this.intervention.validated_arrival !== null
      ) {
        return "custom-ioncard-finished";
      } else {
        return "custom-ioncard-unfinished";
      }
    },
  },
  methods: {
    formatDate(date) {
      let d = new Date(date);
      let hours = d.getHours();
      let mins = d.getMinutes();

      if (hours < 10) hours = "0" + hours;
      if (mins < 10) mins = "0" + mins;

      return hours + ":" + mins;
    },
    printTelephone() {
      console.log("Telephone clicked");
    },
  },
  created() {
    if (this.intervention.job_start != null)
      this.interventionDate = this.formatDate(this.intervention.job_start);
  },
};
</script>

<style scoped>
.custom-ioncard-unfinished {
  padding: 10px;
  background: linear-gradient(#e66465, #9198e5);
}
.custom-ioncard-finished {
  padding: 10px;
  background: linear-gradient(#87da4f, #85e4d7);
}
.item-card {
  border-radius: 10px 10px 10px 10px;
  margin: 5px;
}
</style>
