<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label position="fixed">{{ translate("Quantity") }}</ion-label>
        <ion-input
          type="number"
          v-model="GStore.task.quantity"
          :placeholder="translate('Times the task was performed')"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label
          >{{ translate("Standard") }} {{ translate("Tasks") }}</ion-label
        >
        <ion-select
          v-model="GStore.task.task_category_id"
          :placeholder="
            translate('Select') +
              ' ' +
              translate('Standard') +
              ' ' +
              translate('Tasks')
          "
          v-if="isDataLoaded"
        >
          <ion-label>{{ translate("Category") }}</ion-label>
          <ion-select-option
            v-for="(category, index) in taskCategories"
            :key="index"
            :value="category.id"
            >{{ category.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="fixed">{{ translate("Title") }}</ion-label>
        <ion-input type="text" v-model="GStore.task.title"></ion-input>
      </ion-item>
      <ion-item v-if="formType == 'Update'">
        <ion-label position="floating">{{
          translate("Description")
        }}</ion-label>
        <ion-textarea
          v-model="GStore.task.new_description"
          :disabled="formType == 'Update'"
        ></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{
          translate("Technician") + " " + translate("Description")
        }}</ion-label>
        <ion-textarea
          v-model="GStore.task.technician_description"
        ></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button @click="$router.go(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import TaskService from "@/services/task.service";
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  toastController,
} from "@ionic/vue";
export default {
  name: "AddEditTaskForm",
  props: {
    plannedTask: {
      type: String,
      default: "false",
    },
    formType: {
      type: String,
      default: "Create",
    },
  },
  components: {
    IonLabel,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
  },
  data() {
    return {
      taskCategories: [],
    };
  },
  inject: ["GStore"],
  computed: {
    createForm() {
      return Object.keys(this.GStore.task).length === 0;
    },
    isDataLoaded() {
      return this.taskCategories.length !== 0;
    },
    // taskCategoryName() {
    //   console.log("asdasd", this.GStore.task.task_category_id);
    //   if (this.GStore.task.task_category_id != null) {
    //     this.taskCategories.forEach((element) => {
    //       if (
    //         element.id == this.GStore.task.task_category_id &&
    //         element.name == "Undefined"
    //       ) {
    //         console.log("55", element.name);
    //         return element.name;
    //       }
    //     });
    //   }
    //   return null;
    // },
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async submitForm() {
      let serviceFunctionName =
        this.formType === "Create" ? "createTask" : "updateTask";
      if (this.plannedTask == "true") {
        serviceFunctionName = "createTask";
      }
      console.log(89347234, serviceFunctionName);
      await TaskService[serviceFunctionName](this.GStore.task)
        .then(async () => {
          const taskAction = this.formType === "Create" ? "created" : "updated";
          const toast = await toastController.create({
            message:
              this.translate("Task") + " was " + this.translate(taskAction),
            position: "bottom",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          this.fetchIntervention(this.GStore.task.taskable_id).catch(
            (error) => {
              this.$router.push({ name: "ErrorDisplay", params: { error } });
            }
          );
          this.$router.replace({
            name: "InterventionTabsRoute",
            params: { id: this.GStore.task.taskable_id },
          });
        })
        .catch(async (error) => {
          console.log("89234", error.response.data);
          const toast = await toastController.create({
            message: this.translate(error.response.data.message),
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
  },
  async created() {
    await TaskService.getTaskCategories()
      .then((response) => {
        this.taskCategories = response.data;
      })
      .catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
  },
};
</script>

<style></style>
