<template>
  <ion-content class="ion-padding">
    {{ translate("Tasks") + " " + translate("list") }}
    <task-list :tasks="tasks" :projectTasks="projectTasks"></task-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddTaskClick">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</template>

<script>
import { IonContent, IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { add } from "ionicons/icons";
import TaskList from "@/components/tasks/List";
export default {
  props: ["tasks", "projectTasks", "interventionId"],
  inject: ["GStore"],
  components: { IonContent, IonFabButton, IonFab, IonIcon, TaskList },
  data() {
    return {
      add,
    };
  },
  methods: {
    handleAddTaskClick() {
      this.GStore.task = {
        taskable_id: this.interventionId,
        status: "completed",
      };
      this.$router.push({
        name: "TaskCreateEdit",
        params: { formType: "Create", plannedTask: false },
      });
    },
  },
};
</script>

<style>
</style>