export default
    {
        "Project": "Project",
        "Projects": "Projects",
        "Intervention": "Intervention",
        "Interventions": "Interventions",
        "Create": "Create",
        "Update": "Update",
        "process": "process",
        'list': 'list',
        "Details": "Details",
        "Show more": "Show more",
        "Unfinished Interventions": "Unfinished Interventions",
        "Profile": "Profile",
        "Location": "Location",
        "Hours": "Hours",
        "Tasks": "Tasks",
        "Photos": "Photos",
        "Products": "Products",
        "Docs": "Docs",
        "Tags": "Tags",
        "Task": "Task",
        "Photo": "Photo",
        "Product": "Product",
        "Doc": "Doc",
        "Tag": "Tag",
        "Planned": "Planned",
        "UnPlanned": "UnPlanned",
        "Completed": "Completed",
        "Filter": "Filter",
        "Estimated": "Estimated",
        "Used": "Used",
        "Locked": "Locked",
        "Usable": "Usable",
        "Released": "Released",
        "Today": "Today",
        "Tomorrow": "Tomorrow",
        "Monday": "Monday",
        "Planning": "Planning",
        "Home": "Home",
        "Open": "Open",
        "Close": "Close",
        "Title": "Title",
        "Start": "Start",
        "End": "End",
        "Save": "Save",
        "Cancel": "Cancel",
        "Description": "Description",
        "Delete": "Delete",
        "After": "After",
        "Before": "Before",
        "Quantity": "Quantity",
        "Comment": "Comment",
        "Please Sign In!": "Please Sign In!",
        "Sign In": "Sign In",
        "Username": "Username",
        "Password": "Password",
        "Failed": "Failed",
        "Sign in Failed": "Sign in Failed",
        "PLANNING": "PLANNING",
        "INTERVENTIONS": "INTERVENTIONS",
        "PROJECTS": "PROJECTS",
        "PROFILE": "PROFILE",
        "Logging out": "Logging out",
        "Logout of the application?": "Logout of the application?",
        "Settings": "Settings",
        "Birthdate": "Birthdate",
        "Vehicle": "Vehicle",
        "AVS": "AVS",
        "IBAN": "IBAN",
        "Bank": "Bank",
        "System Configuration": "System Configuration",
        "Importance": "Importance",
        "Normal": "Normal",
        "Critical": "Critical",
        "type": "type",
        "Standard": "Standard",
        "Category": "Category",
        "Process": "Process",
        "tab": "tab",
        "Refreshing": "Refreshing",
        "Show more detail": "Show more detail",
        "The given data was invalid": "The given data was invalid",
        "Cannot update intervention": "Cannot update intervention",
        "Cannot update product transaction": "Cannot update product transaction",
        "Cannot edit lock more than Available quantity": "Cannot edit lock more than Available quantity",
        "Cannot edit lock less than already used material quantity": "Cannot edit lock less than already used material quantity",
        "Lock is already released cannot edit material": "Lock is already released cannot edit material",
        "Cannot use product more than Available quantity": "Cannot use product more than Available quantity",
        "Cannot delete used product": "Cannot delete used product",
        "Lock is already released for this product": "Lock is already released for this product",
        "Cannot delete used product transaction: not of type out": "Cannot delete used product transaction: not of type out",
        "Lock quantity for this product is already released cannot add as used": "Lock quantity for this product is already released cannot add as used",
        "Cannot find estimated product of type lock": "Cannot find estimated product of type lock",
        "Cannot release lock employee is not assigned to any intervention for this project": "Cannot release lock employee is not assigned to any intervention for this project",
        "Cannot release lock of 0 quantity": "Cannot release lock of 0 quantity",
        "Cannot create task": "Cannot create task",
        "Cannot delete Task": "Cannot delete Task",
        "Cannot add task as completed": "Cannot add task as completed",
        "Name": "Name",
        "System Language": "System Language",
        "Phone": "Phone",
        "Address": "Address",
        "Email Address": "Email Address",
        "Postal Code": "Postal Code",
        "City": "City",
        "Departure": "Departure",
        "Onsite": "Onsite",
        "Finished": "Finished",
        "Arrival": "Arrival",
        "Other": "Other",
        "Edit and Add": "Edit and Add",
        "Are you sure to delete this Task?": "Are you sure to delete this Task?",
        "Use": "Use",
        "Edit Lock": "Edit Lock",
        "Edit and use": "Edit and use",
        "Times the task was performed": "Times the task was performed",
        "Select": "Select",
        "CONFIRM INTERVENTION": "CONFIRM INTERVENTION",
        "GARAGE DEPARTURE": "GARAGE DEPARTURE",
        "ON SITE": "ON SITE",
        "FINISHED": "FINISHED",
        "GARAGE ARRIVAL": "GARAGE ARRIVAL",
        "Is this project finished?": "Is this project finished?",
        "Are you sure to delete this Product?": "Are you sure to delete this Product?",
        "The photo was uploaded": "The photo was uploaded",
        "created": "created",
        "updated": "updated",
        "deleted": "deleted",
        "The product was added as used": "The product was added as used",
        "The product was deleted": "The product was deleted",
        "The Estimated lock is released": "The Estimated lock is released",
        "The user was updated": "The user was updated",
        "The task was added as completed": "The task was added as completed",
        "The project was updated to finished": "The project was updated to finished",
        "Enter description of task": "Enter description of task",
        "Intervention confirmed!": "Intervention confirmed!",
        "Location sent!": "Location sent!",
        "Employee": "Employee",
        "Timezone": "Timezone",
        "Status": "Status",
        "Please wait": "Please wait",
        "Pull to refresh": "Tirer pour rafraîchir",
        "Week": "Week",
        "Month": "Month",
        "Refresh": "Refresh",
        "2 Day": "2 Day",
        "History": "History",
        "Technician": "Technician",
        "Planned task?": "Planned task?",
        "Floor": "Floor",
        "The project was updated to in process": "The project was updated to in process",
        "Contact": "Contact",
        "Contact phone": "Contact phone",
        "private": "Private",
        "common_place": "Common place",
        "tenant": "Tenant",
        "Tenant": "Tenant",
        "Tenant Phone": "Tenant phone",
        "Projects in process": "Projects in process",
        "Order number": "Order number",
        "Quotation number": "Quotation number",
        "The start date and end date must be the same": "The start date and end date must be the same",
        "The end date must be after the start date": "The end date must be after the start date",
        "You cannot create an intervention in the past": "You cannot create an intervention in the past",
        "Admin": "Admin",
        "Owner": "Owner",
        "You can only create an intervention starting 2 hours in the past max": "You can only create an intervention starting 2 hours in the past max",
        "No": "No",
        "Yes": "Yes",
        "Extra work hours": "Extra work hours",
        "Did you have extra work?": "Did you have extra work?",
        "Extra work details": "Extra work details",
        "Extra work description": "Extra work description",
        "Extra work time": "Extra work time",
        "The details were added": "The details were added",
        "Go to details": "Go to details",
        "Project planned tasks": "Project planned tasks",
        "Intervention planned tasks": "Intervention planned tasks",
        "Completed tasks": "Completed tasks",
        "Finished Projects": "Finished Projects",
        "Project Details": "Project Details",
        "Type in search to see project": "Type in search to see project",
        "Search": "Search",
        "Technician Intervention Description": "Technician Intervention Description",
        "Add": "Add",
        "noTsForThisIntervention": "You haven't added any TS for this intervention",
        "pleaseAddDescriptionAndTime": "Please add a description and time",
        "typeOfMaterial": "Type of material",
        "pleaseSelectAProduct": "Please select a product",
        "tsMaterial": "TS Material",
        "normalMaterial": "Normal Material",
        "pleaseSelectTypeOfMaterial": "Please select type of material",
        "theProductWasAdded": "The product was added",
        "theProductWasUpdated": "The product was updated",
    }