import ApiService from "./api.service";

const UserService = {
    async getInfo(key) {
        return ApiService.get('/api/user/details', key)
    },
    async updateUser(user) {
        return ApiService.post('/api/user/update', user)
    }
}

export default UserService