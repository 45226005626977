<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismissModal()" color="light">{{
            translate("Close")
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-searchbar
      animated
      v-model="search"
      :placeholder="translate('Filter')"
      show-cancel-button="focus"
      debounce="200"
    ></ion-searchbar>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item
          class=""
          v-for="(item, index) in filteredlistItems"
          :key="index"
        >
          <ion-label class="ion-text-start" @click="handleItemClick(item.id)">
            {{ selectType == "products" ? item.name : item.title }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  modalController,
  IonSearchbar,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
} from "@ionic/vue";
export default {
  name: "SearchableSelect",
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  props: {
    items: {
      type: Array,
    },
    selectType: {
      type: String,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
    async handleItemClick(itemId) {
      await modalController.dismiss(itemId);
    },
  },
  computed: {
    listItems() {
      return this.items;
    },
    filteredlistItems() {
      return this.listItems.filter((listItem) => {
        if (this.selectType == "products")
          return (
            listItem.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        else
          return (
            listItem.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
      });
    },
  },
  created() {},
};
</script>

<style>
</style>