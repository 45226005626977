<template>
  <ion-item-sliding>
    <ion-item-options>
      <ion-item-option color="danger" @click="presentDeleteAlert">
        {{ translate("Delete") }}
      </ion-item-option>
      <ion-item-option color="warning" @click="handleEdit">
        {{ translate("Update") }}
      </ion-item-option>
      <ion-item-option v-if="photo.before_id === null" @click="addAfter">
        {{ translate("Create") + " " + translate("After") }}
      </ion-item-option>
    </ion-item-options>
    <ion-item :class="itemClass" @click="openModal()">
      <ion-thumbnail slot="start">
        <ion-img :src="photoUrl"></ion-img>
      </ion-thumbnail>
      <ion-label> {{ photo.description }} </ion-label>
      <ion-label color="danger" v-if="before" slot="end">{{
        translate("Before")
      }}</ion-label>
    </ion-item>
  </ion-item-sliding>
</template>

<script>
import PhotoService from "@/services/photo.service";
import { mapActions, mapState } from "vuex";
import PhotoModal from "./Modal";
import {
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  alertController,
  toastController,
  IonThumbnail,
  IonImg,
  modalController,
} from "@ionic/vue";
export default {
  name: "PhotoListItem",
  // props: ["photo", "itemClass"],
  props: {
    photo: {
      type: Object,
    },
    itemClass: {
      type: String,
      default: "row0",
    },
    before: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonItemSliding,
    IonItem,
    IonItemOptions,
    IonItemOption,
    IonLabel,
    IonThumbnail,
    IonImg,
  },
  inject: ["GStore"],
  data() {
    return {
      photoUrl: "",
    };
  },
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    addAfter() {
      this.GStore.photo = {
        job_id: this.currentIntervention.id,
        before_id: this.photo.id,
      };
      this.$router.push({
        name: "PhotoCreateEdit",
        params: { formType: "Create" },
      });
    },
    async openModal() {
      const modal = await modalController.create({
        component: PhotoModal,
        componentProps: {
          id: this.photo.id,
          description: this.photo.description,
        },
      });
      await modal.present();
    },
    async presentDeleteAlert() {
      const alert = await alertController.create({
        header: this.translate("Delete") + " " + this.translate("Photo"),
        message:
          "<strong>" +
          this.translate("Are you sure to delete this Photo?") +
          "</strong>",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: "Delete",
            role: "ok",
            cssClass: "danger",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === "ok") {
        await PhotoService.deletePhoto(this.photo.id)
          .then(async () => {
            const toast = await toastController.create({
              message:
                this.translate("Photo") + " was " + this.translate("deleted"),
              position: "bottom",
              duration: 4000,
              color: "success",
            });
            await toast.present();
            this.fetchIntervention(this.currentIntervention.id).catch(
              (error) => {
                this.$router.push({ name: "ErrorDisplay", params: { error } });
              }
            );
          })
          .catch(async (error) => {
            const toast = await toastController.create({
              message: error,
              position: "bottom",
              duration: 4000,
              color: "danger",
            });
            await toast.present();
          });
      }
    },
    handleEdit() {
      this.GStore.photo = this.photo;
      this.$router.push({
        name: "PhotoCreateEdit",
        params: { formType: "Update" },
      });
    },
  },
  async created() {
    const photoBinaries = await PhotoService.downloadThumbnail(this.photo.id)
      .then((response) => response.data)
      .catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
    this.photoUrl = photoBinaries;
  },
};
</script>

<style>
.row1 {
  --background: var(--ion-color-light);
}

.row0 {
  --background: #ffffff;
}
</style>