import ApiService from "./api.service";

const TagService = {
    async deleteTag(id) {
        return ApiService.delete('/api/tags/' + id)
    },

    async createTag(tag) {
        return ApiService.post('/api/tags', tag)
    },

    async updateTag(tag) {
        return ApiService.post('api/tags/' + tag.id, tag)
    },

    async getTags(interventionId) {
        return ApiService.get('/api/tags/get-intervention-tags/' + interventionId)
    },
}

export default TagService