<template>
  <ion-img :src="photoUrl"></ion-img>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-item>
      <ion-label position="floating">{{ translate("Description") }}</ion-label>
      <ion-textarea
        :placeholder="translate('Description')"
        v-model="GStore.photo.description"
      ></ion-textarea>
    </ion-item>
    <ion-button @click="$router.go(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import PhotoService from "@/services/photo.service";
import { mapActions } from "vuex";
import {
  IonItem,
  IonImg,
  IonLabel,
  IonButton,
  IonTextarea,
  toastController,
} from "@ionic/vue";
export default {
  name: "EditPhotoForm",
  components: {
    IonLabel,
    IonItem,
    IonButton,
    IonTextarea,
    IonImg,
  },
  inject: ["GStore"],
  data() {
    return {
      photoUrl: "",
    };
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async submitForm() {
      await PhotoService.updatePhoto(this.GStore.photo)
        .then(async () => {
          const toast = await toastController.create({
            message:
              this.translate("Photo") + " was " + this.translate("updated"),
            position: "bottom",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          this.fetchIntervention(this.GStore.photo.job_id).catch((error) => {
            this.$router.push({ name: "ErrorDisplay", params: { error } });
          });
          this.$router.replace({
            name: "InterventionTabsRoute",
            params: { id: this.GStore.photo.job_id },
          });
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: error,
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
  },
  async created() {
    const photoBinaries = await PhotoService.downloadPhoto(this.GStore.photo.id)
      .then((response) => response.data)
      .catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
    this.photoUrl = photoBinaries;
  },
};
</script>

<style>
</style>