<template>
  <ion-item class="" @click="openModal()">
    <ion-label>
      {{ completedTask.quantity }}
    </ion-label>
    <ion-label class="ion-text-wrap">
      <ion-text>
        {{ completedTask.task_category.name }}
      </ion-text>
    </ion-label>
  </ion-item>
</template>

<script>
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { IonItem, IonLabel, IonText, modalController } from "@ionic/vue";
import TaskModal from "./Modal";
export default {
  props: {
    completedTask: {
      type: Object,
    },
  },
  components: {
    IonItem,
    IonLabel,
    IonText,
  },
  data() {
    return {
      checkmarkCircleOutline,
      closeCircleOutline,
    };
  },
  computed: {},
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: TaskModal,
        componentProps: {
          id: this.completedTask.id,
          quantity: this.completedTask.quantity,
          category: this.completedTask.task_category.name,
          description: this.completedTask.new_description,
        },
      });
      await modal.present();
    },
  },
};
</script>