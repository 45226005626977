<template>
  <ion-card
    class="custom-ioncard"
    :router-link="{
      name: 'ProjectDetails',
      params: { id: project.id },
    }"
  >
    <ion-card-title>
      <strong>{{ " (" + project.id + ") " + project.title }}</strong>
    </ion-card-title>
    <ion-card-content>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="location" color="danger" size="large"></ion-icon>
        <ion-label style="padding-left: 10px">
          <h2>{{ project.street_name }}</h2>
          <p>{{ project.postal_code }}</p>
          <p v-if="project.floor != null">
            {{ translate("Floor") + " : " + project.floor }}
          </p>
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="project.tenant != null">
        <ion-icon :icon="person" color="secondary" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ project.tenant }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="project.tenant_phone != null">
        <ion-icon :icon="call" color="tertiary" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ project.tenant_phone_one }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="project.contact != null">
        <ion-icon :icon="person" color="secondary" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ project.contact }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" v-if="project.contact_phone != null">
        <ion-icon :icon="call" color="tertiary" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ project.contact_phone }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="construct" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding ion-text-wrap">
          {{ project.task_description }}
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { location, person, call, construct } from "ionicons/icons";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonIcon,
} from "@ionic/vue";
export default {
  name: "ProjectListItem",
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    IonLabel,
    IonCardTitle,
    IonCardContent,
    IonCard,
    IonItem,
    IonIcon,
  },
  data() {
    return {
      location,
      person,
      call,
      construct,
    };
  },
};
</script>

<style scoped>
.custom-ioncard {
  padding: 10px;
  background: linear-gradient(#87da4f, #85e4d7);
}
.item-card {
  border-radius: 10px 10px 10px 10px;
  margin: 5px;
}
</style>