<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label position="fixed">{{ translate("Title") }}</ion-label>
        <ion-input
          type="text"
          v-model="GStore.tag.title"
          :placeholder="translate('Title')"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{
          translate("Description")
        }}</ion-label>
        <ion-textarea
          :placeholder="translate('Enter description of task')"
          v-model="GStore.tag.description"
        ></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Importance") }}: </ion-label>
        <ion-select v-model="GStore.tag.importance">
          <ion-select-option value="normal">{{
            translate("Normal")
          }}</ion-select-option>
          <ion-select-option value="critical">{{
            translate("Critical")
          }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-button @click="$router.go(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import TagService from "@/services/tag.service";
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonTextarea,
  toastController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
export default {
  name: "AddEditTagForm",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
  },
  components: {
    IonLabel,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonTextarea,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {};
  },
  inject: ["GStore"],
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    ...mapActions("tag", ["fetchInterventionTags"]),
    async submitForm() {
      let serviceFunctionName =
        this.formType === "Create" ? "createTag" : "updateTag";
      await TagService[serviceFunctionName](this.GStore.tag)
        .then(async () => {
          const tagAction = this.formType === "Create" ? "created" : "updated";
          const toast = await toastController.create({
            message:
              this.translate("Tag") + " was " + this.translate(tagAction),
            position: "bottom",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          this.fetchIntervention(this.GStore.tag.job_id).catch((error) => {
            this.$router.push({ name: "ErrorDisplay", params: { error } });
          });
          this.fetchInterventionTags(this.GStore.tag.job_id).catch((error) => {
            this.$router.push({ name: "ErrorDisplay", params: { error } });
          });
          this.$router.replace({
            name: "InterventionTabsRoute",
            params: { id: this.GStore.tag.job_id },
          });
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data.message),
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
  },
};
</script>

<style>
</style>