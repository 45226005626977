<template>
  <ion-content class="ion-padding">
    {{ translate("Products") + " " + translate("list") }}
    <product-list
      :products="products"
      :projectProducts="projectProducts"
    ></product-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddProductClick">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</template>

<script>
import { IonContent, IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { add } from "ionicons/icons";
import ProductList from "@/components/products/List.vue";
export default {
  inject: ["GStore"],
  components: {
    ProductList,
    IonContent,
    IonFabButton,
    IonFab,
    IonIcon,
  },
  props: {
    products: Array,
    projectProducts: Array,
    interventionId: String,
  },
  data() {
    return {
      add,
    };
  },
  methods: {
    handleAddProductClick() {
      this.GStore.product = {
        job_id: this.interventionId,
        type: "out",
      };
      this.$router.push({
        name: "ProductCreateEdit",
        params: {
          formType: "Create",
          projectProduct: false,
          componentKey: "add",
        },
      });
    },
  },
};
</script>

<style>
</style>