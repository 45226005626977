<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{ translate("Home") }}</ion-title>
        <ion-button
          slot="end"
          color="tertiary"
          fill="solid"
          @click="presentAlertConfirm"
        >
          <ion-icon slot="icon-only" :icon="logOut"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row v-for="(row, indexRow) in gridComponents" :key="indexRow">
          <ion-col v-for="(card, indexCol) in row" :key="indexCol" size="6">
            <ion-card @click="routeName(card.route)">
              <ion-card-header>
                <ion-icon
                  :icon="card.icon.type"
                  size="large"
                  :color="card.icon.color"
                ></ion-icon>
              </ion-card-header>
              <ion-card-content>
                <ion-card-title :color="card.icon.color" class="text-2xl">
                  <strong>{{ card.title }}</strong>
                </ion-card-title>
                <ion-card-subtitle>{{ card.subtitle }}</ion-card-subtitle>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-label>Version 1.2.7</ion-label>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  alertController,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonLabel
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  logOut,
  calendarNumber,
  construct,
  folderOpen,
  person,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { mapActions } from "vuex";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonLabel
  },
  data() {
    return {
      logOut,
      router: useRouter(),
      generateKey: 0,
      gridComponents: [
        [
          {
            title: this.translate("PLANNING"),
            subtitle: "",
            icon: { type: calendarNumber, color: "primary" },
            route: "Planning",
          },
          {
            title: this.translate("INTERVENTIONS"),
            subtitle: "",
            icon: { type: construct, color: "danger" },
            route: "Interventions",
          },
        ],
        [
          {
            title: this.translate("PROJECTS"),
            subtitle: "",
            icon: { type: folderOpen, color: "success" },
            route: "Projects",
          },
          {
            title: this.translate("PROFILE"),
            subtitle: "",
            icon: { type: person, color: "tertiary" },
            route: "Profile",
          },
        ],
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    handleLogout() {
      this.signOut();
      this.router.push("/login");
    },
    async presentAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: this.translate("Logging out"),
        message:
          "<strong>" +
          this.translate("Logout of the application?") +
          "</strong>",
        buttons: [
          {
            text: this.translate("Cancel"),
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: "Ok",
            role: "ok",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === "ok") {
        this.signOut();
        this.router.push("/login");
      }
    },
    routeName(route) {
      this.generateKey++;
      let params = {};
      if (route == "Profile") {
        params.profileKey = this.generateKey;
      }
      this.router.push({
        name: route,
        params,
      });
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>