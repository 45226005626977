import InterventionService from '../services/intervention.service.js'

const state = {
    finishedInterventions: [],
    unfinishedInterventions: [],
    currentIntervention: {},
    todayTomorrowInterventions: [],
    mondayInterventions: [],
}

const getters = {
    interventions: (state) => {
        return state.unfinishedInterventions.concat(state.finishedInterventions);
    },
    currentInterventionData: (state) => {
        return { ...state.currentIntervention };
    },
    todayTomorrowInterventions: (state) => {
        return { ...state.todayTomorrowInterventions }
    },
    todayTomorrowInterventionsNotCancelled: (state) => {
        return state.todayTomorrowInterventions.filter(intervention => {
            return intervention.project.status != 'cancelled'
        })
    },
    mondayInterventions: (state) => {
        return state.mondayInterventions.filter(intervention => {
            return intervention.project.status != 'cancelled'
        })
    }
}

const mutations = {
    SET_UNFINISHED_INTERVENTIONS(state, interventions) {
        state.unfinishedInterventions = interventions
    },

    SET_FINISHED_INTERVENTIONS(state, interventions) {
        state.finishedInterventions = interventions
    },

    SET_CURRENT_INTERVENTION(state, intervention) {
        state.currentIntervention = Object.assign({}, JSON.parse(JSON.stringify(intervention)))
    },

    SET_TODAY_TOMORROW_INTERVENTIONS(state, interventions) {
        state.todayTomorrowInterventions = interventions
    },

    SET_MONDAY_INTERVENTIONS(state, interventions) {
        state.mondayInterventions = interventions
    }
}

const actions = {
    fetchInterventions() { },

    fetchUnfinishedInterventions({ commit }) {
        return InterventionService.getUnfinishedInterventions()
            .then(response => {
                commit('SET_UNFINISHED_INTERVENTIONS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },

    fetchIntervention({ commit }, id) {
        return InterventionService.getIntervention(id)
            .then(response => {
                commit('SET_CURRENT_INTERVENTION', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },

    fetchTodayTomorrowInterventions({ commit }) {
        return InterventionService.getTodayAndTomorrowInterventions()
            .then(response => {
                commit('SET_TODAY_TOMORROW_INTERVENTIONS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },

    fetchMondayInterventions({ commit }) {
        return InterventionService.getNextMondayInterventions()
            .then(response => {
                commit('SET_MONDAY_INTERVENTIONS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    }

}

export const intervention = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}