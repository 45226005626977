<template>
  <ion-item :class="itemClass" @click="openModal()">
    <ion-icon :icon="locationSharp" color="danger"></ion-icon>
    <ion-label> {{ translate(location.name) }}</ion-label>
    <ion-label>
      {{ location.time }}
    </ion-label>
  </ion-item>
</template>

<script>
import { locationSharp } from "ionicons/icons";
import { IonItem, IonLabel, IonIcon, modalController } from "@ionic/vue";
import MapModal from "./MapModal";
export default {
  name: "LocationItem",
  components: { IonItem, IonLabel, IonIcon },
  props: {
    location: {
      type: Object,
    },
    itemClass: {
      type: String,
      default: "row0",
    },
  },
  data() {
    return {
      locationSharp,
    };
  },
  methods: {
    async openModal() {
      const point =
        this.location.point != null ? this.location.point.coordinates : null;
      const modal = await modalController.create({
        component: MapModal,
        componentProps: {
          locationName: this.location.name,
          point: point,
        },
      });
      await modal.present();
    },
  },
};
</script>

<style scopedº>
.row0 {
  --background: var(--ion-color-light);
}

.row1 {
  --background: #ffffff;
}
</style>