<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-left"
        ><strong>
          {{ translate("Other") + " " + translate("Details") }}
        </strong></ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{
          translate("Close")
        }}</ion-button>
        <ion-button
          @click="redirectToDetails"
          v-if="redirectedFrom == 'calendar'"
        >
          {{ translate("Go to details") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-card class="custom-ioncard">
      <ion-card-title>
        <strong>{{
          "(" +
            currentInterventionData.id +
            ") " +
            currentInterventionData.title
        }}</strong>
      </ion-card-title>
      <ion-card-content>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="carOutline" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              currentInterventionData.vehicle != null
                ? currentInterventionData.vehicle.name
                : ""
            }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon
            :icon="constructOutline"
            color="danger"
            size="large"
          ></ion-icon>
          <ion-label
            style="padding-left: 10px; margin-left: 5%"
            class="ion-text-wrap"
          >
            {{ currentInterventionData.project.task_description }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="location" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              "Place type: " +
                translate(currentInterventionData.project.place_type)
            }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="person" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              translate("Tenant") +
                ": " +
                checkNullValue(currentInterventionData.project.tenant)
            }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="call" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              translate("Tenant Phone") +
                ": " +
                checkNullValue(currentInterventionData.project.tenant_phone_one)
            }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="person" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              translate("Contact") +
                ": " +
                checkNullValue(currentInterventionData.project.contact)
            }}
          </ion-label>
        </ion-item>
        <ion-item class="item-card" background="warning">
          <ion-icon :icon="call" color="danger" size="large"></ion-icon>
          <ion-label style="padding-left: 10px; margin-left: 5%">
            {{
              translate("Contact phone") +
                ": " +
                checkNullValue(currentInterventionData.project.contact_phone)
            }}
          </ion-label>
        </ion-item>
        <ion-item
          class="item-card"
          background="warning"
          v-if="
            currentInterventionData.technician_intervention_description != null
          "
        >
          <ion-icon
            :icon="listOutline"
            color="secondary"
            size="large"
          ></ion-icon>
          <ion-label class="ion-text-wrap ion-padding">
            {{
              translate("Technician Intervention Description") +
                ": " +
                currentInterventionData.technician_intervention_description
            }}
          </ion-label>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  modalController,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  person,
  carOutline,
  constructOutline,
  listOutline,
  call,
  location,
} from "ionicons/icons";
import { mapGetters } from "vuex";
export default defineComponent({
  name: "SecondaryInfoModal",
  props: {
    redirectedFrom: {
      type: String,
      default: "intervention-details",
    },
  },
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonIcon,
  },
  data() {
    return {
      person,
      carOutline,
      constructOutline,
      listOutline,
      call,
      location,
    };
  },
  computed: {
    ...mapGetters("intervention", ["currentInterventionData"]),
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
    checkNullValue(value) {
      return value ?? "-";
    },
    async redirectToDetails() {
      this.$router.replace({
        name: "InterventionTabsRoute",
        params: {
          id: this.currentInterventionData.id,
        },
      });
      await modalController.dismiss();
    },
  },
});
</script>

<style scoped>
.custom-ioncard {
  padding: 10px;
  background: linear-gradient(
    to left,
    rgba(250, 0, 0, 0.3),
    rgba(255, 0, 0, 0.8)
  );
}

.item-card {
  border-radius: 10px 10px 10px 10px;
  margin: 5px;
}
</style>
