import ApiService from "./api.service";

const InterventionService = {
    getInterventions(start, end) {
        const params = {
            start,
            end
        }
        return ApiService.customRequest({
            url: 'api/interventions',
            method: 'get',
            params
        })
    },

    async getUnfinishedInterventions() {
        return ApiService.get('/api/unfinished-interventions')
    },

    getIntervention(id) {
        return ApiService.get('api/interventions/' + id)
    },

    confirmIntervention(id) {
        return ApiService.post('api/confirm-intervention/' + id)
    },

    sendPosition(location, id, position) {
        return ApiService.post('api/submit-position/' + location + '/' + id, position)
    },

    finishProject(projectId) {
        return ApiService.post('api/finish-project/' + projectId)
    },

    async createIntervention(intervention) {
        return ApiService.post('/api/interventions', intervention)
    },

    async updateIntervention(intervention) {
        return ApiService.post('api/interventions/' + intervention.id, intervention)
    },

    getInterventionDetails(id) {
        return ApiService.get('api/interventions/get-intervention-details/' + id)
    },

    getTodayAndTomorrowInterventions() {
        return ApiService.get('api/today-tomorrow-interventions')
    },

    setExtraHoursDetails(id, extraHoursDescription, extraHoursTime) {
        return ApiService.post('api/intervention/' + id + '/set-extra-hours-details', { extraHoursDescription, extraHoursTime })
    },

    getNextMondayInterventions() {
        return ApiService.get('api/monday-interventions')
    },

    setIfProjectIsFinished(id, isProjectFinished) {
        return ApiService.post('api/intervention/' + id + '/set-if-project-finished', { isProjectFinished })
    }

}

export default InterventionService