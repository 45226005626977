<template>
  <base-layout
    :page-title="
      showInProcess
        ? translate('Projects in process')
        : translate('Finished Projects')
    "
    :pageDefaultBackLink="backDefaultUrl"
    :showFooter="true"
  >
    <template v-slot:actions-end>
      <ion-button @click="refreshComponent">
        <ion-icon :icon="refresh" slot="icon-only"> </ion-icon>
      </ion-button>
    </template>
    <ion-searchbar
      v-model="search"
      :placeholder="translate('Search') + ' ' + translate('Projects')"
      show-cancel-button="focus"
      debounce="100"
    ></ion-searchbar>
    <span class="ion-padding" v-if="search == ''">{{
      translate("Type in search to see project")
    }}</span>
    <project-list
      :key="projectRefreshKey"
      :projects="
        showInProcess ? filteredInProcessProjects : filteredFinishedProjects
      "
    ></project-list>
    <template v-slot:footerContent>
      <ion-toolbar class="footer">
        <ion-segment @ionChange="tabChange($event)" value="in_process">
          <ion-segment-button value="in_process">
            <ion-icon :icon="folderOpen"></ion-icon>
            <ion-label>{{ translate("Projects in process") }}</ion-label>
            <ion-badge class="badge-position" color="warning">{{
              inProcessProjects.length
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button value="finished">
            <ion-icon :icon="folder"></ion-icon>
            <ion-label>{{ translate("Finished Projects") }}</ion-label>
            <ion-badge class="badge-position" color="warning">{{
              finishedProjects.length
            }}</ion-badge>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/base/BaseLayout.vue";
import ProjectList from "@/components/projects/ProjectList.vue";
import { mapActions, mapGetters } from "vuex";
import { folderOpen, folder, refresh } from "ionicons/icons";
import {
  IonIcon,
  IonLabel,
  IonBadge,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,
    IonIcon,
    IonLabel,
    IonBadge,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonSearchbar,
    ProjectList,
  },
  data() {
    return {
      showInProcess: true,
      refresh,
      projectRefreshKey: 0,
      folderOpen,
      folder,
      search: "",
    };
  },
  computed: {
    ...mapGetters("project", ["inProcessProjects", "finishedProjects"]),
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Home",
      });
      return href;
    },
    filteredInProcessProjects() {
      if (this.search !== "" && this.showInProcess == true)
        return this.inProcessProjects.filter((project) =>
          this.searchFilter(project)
        );
      return this.inProcessProjects;
    },
    filteredFinishedProjects() {
      if (this.search !== "" && this.showInProcess == false)
        return this.finishedProjects.filter((project) =>
          this.searchFilter(project)
        );
      return this.finishedProjects;
    },
  },
  methods: {
    ...mapActions("project", ["fetchAssignedToProjects"]),
    searchFilter(project) {
      return (
        project.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
        project.id == this.search
      );
    },
    tabChange(event) {
      const tabNameClicked = event.detail.value;
      this.showInProcess = false;
      if (tabNameClicked === "in_process") this.showInProcess = true;
      this.projectRefreshKey++;
    },
    async refreshComponent() {
      await this.fetchAssignedToProjects().catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
      this.projectRefreshKey++;
    },
  },
  async created() {
    await this.fetchAssignedToProjects().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
    this.projectRefreshKey++;
  },
};
</script>
<style scoped>
.footer {
  --background: #ffffff;
  --color: black;
}
.badge-position {
  position: absolute;
  right: 1px;
  top: 5px;
}
</style>
