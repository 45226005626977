<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center"
        ><strong>{{ translate("Task") }}: </strong> {{ taskId }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{
          translate("Close")
        }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <form class="ion-padding" @submit.prevent="addTechnicianDescription">
    <ion-list>
      <ion-item>
        <ion-label position="floating">{{
          translate("Technician") + " " + translate("Description")
        }}</ion-label>
        <ion-textarea v-model="technicianDescription"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button @click="dismissModal(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  modalController,
  toastController,
  IonTextarea,
} from "@ionic/vue";
import { defineComponent } from "vue";
import TaskService from "@/services/task.service";
import { mapActions } from "vuex";
export default defineComponent({
  name: "TaskModal",
  props: {
    taskId: {
      type: Number,
    },
    interventionId: {
      type: Number,
    },
    flag: {
      type: String,
    },
  },
  data() {
    return { technicianDescription: "" };
  },
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonButtons,
    IonTextarea,
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async dismissModal() {
      await modalController.dismiss();
    },
    async addTechnicianDescription() {
      await TaskService.addTechnicianDescription(
        this.taskId,
        this.interventionId,
        this.technicianDescription,
        this.flag
      )
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The task was added as completed"),
            position: "botton",
            duration: "3000",
            color: "success",
          });
          await toast.present();
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
      this.fetchIntervention(this.interventionId).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
      await modalController.dismiss();
    },
  },
});
</script>

<style>
</style>