<template>
  <ion-list>
    <ion-list-header lines="full" class="custom-font-size">
      {{ translate("Employee") + " " + translate("Details") }}
    </ion-list-header>
    <ion-item>
      <ion-icon :icon="call" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start">{{ profileData.phone }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon :icon="location" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start">{{
        profileData.address +
        "\n" +
        profileData.postal_code +
        "\n" +
        profileData.city
      }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon :icon="calendar" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start"
        >{{ translate("Birthdate") }}: {{ profileData.birthdate }}</ion-label
      >
    </ion-item>
    <ion-item>
      <ion-icon :icon="medkit" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start"
        >{{ translate("AVS") }}: {{ profileData.avs }}</ion-label
      >
    </ion-item>
    <ion-item>
      <ion-icon :icon="card" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start"
        >{{ translate("IBAN") }}: {{ profileData.iban }}</ion-label
      >
    </ion-item>
    <ion-item>
      <ion-icon :icon="business" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start"
        >{{ translate("Bank") }}: {{ profileData.bank_name }}</ion-label
      >
    </ion-item>
    <ion-item>
      <ion-icon :icon="car" color="secondary"></ion-icon>
      <ion-label class="ion-padding-start"
        >{{ translate("Vehicle") }}: {{ profileData.vehicle }}</ion-label
      >
    </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonListHeader, IonItem, IonIcon, IonLabel } from "@ionic/vue";
import {
  call,
  location,
  calendar,
  card,
  medkit,
  business,
  car,
} from "ionicons/icons";
export default {
  data() {
    return {
      call,
      location,
      calendar,
      card,
      medkit,
      business,
      car,
    };
  },
  props: {
    profileData: Object,
  },
  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonIcon,
    IonLabel,
  },
};
</script>

<style scoped>
.custom-font-size {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>