<template>
  <ion-content class="ion-padding">
    {{ translate("Doc") + " " + translate("list") }}
    <doc-list :docs="docs" :projectDocs="projectDocs"></doc-list>
  </ion-content>
</template>

<script>
import { IonContent } from "@ionic/vue";
import DocList from "@/components/documents/List.vue";
export default {
  components: {
    DocList,
    IonContent,
  },
  props: {
    docs: Array,
    projectDocs: Array,
  },
};
</script>

<style>
</style>