import ApiService from "./api.service";

const ProductService = {
    async createProductTransaction(product) {
        return ApiService.post('/api/products', product)
    },

    async deleteProductTransaction(id) {
        return ApiService.delete('/api/products/' + id)
    },

    async updateProductTransaction(product) {
        return ApiService.post('/api/products/' + product.id, product)
    },

    async addAsUsedProduct(productTransactionId, interventionId, productId, projectId) {
        return ApiService.post('/api/product/add-as-used', { productTransactionId, interventionId, productId, projectId })
    },

    async getUnsedUnlockedProducts(interventionId) {
        return ApiService.get('/api/product/get-unused-unlocked-products/' + interventionId)
    },

    async getProducts() {
        return ApiService.get('/api/product/get-products')
    },

    async getTotalUsedMaterial(productId, projectId) {
        return ApiService.get('/api/product/total-used-material/' + productId + '/' + projectId)
    },

    async releaseLock(productId, projectId) {
        return ApiService.post('/api/product/release-lock', { productId, projectId })
    },

    async getReleasedQuantity(productId, projectId) {
        return ApiService.get('/api/product/get-released-quantity/' + productId + '/' + projectId)
    },
}

export default ProductService