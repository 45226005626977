<template>
  <base-layout
    :page-title="translate('Planning')"
    :pageDefaultBackLink="backDefaultUrl"
    :showFooter="false"
  >
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddIntervClick">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->
  </base-layout>
</template>

<script>
import BaseLayout from "../../components/base/BaseLayout";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import InterventionService from "@/services/intervention.service";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { add } from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";
import frLocale from "@fullcalendar/core/locales/fr";
import SecondaryInfoModal from "../interventions/details/SecondaryInfoModal.vue";

export default defineComponent({
  name: "Calendar",
  components: {
    BaseLayout,
    FullCalendar,
    // IonFab,
    // IonFabButton,
    // IonIcon,
  },
  data() {
    return {
      add,
      calendarOptions: {
        plugins: [interactionPlugin, timeGridPlugin, dayGridPlugin],
        events: (fetchInfo, successCallback, failureCallback) => {
          InterventionService.getInterventions(fetchInfo.start, fetchInfo.end)
            .then((response) => {
              successCallback(response.data.jobs);
            })
            .catch((error) => {
              failureCallback(error);
              this.$router.push({
                name: "ErrorDisplay",
                params: {
                  error,
                },
              });
            });
        },
        initialView: "timeGridTwoDay",
        views: {
          timeGridTwoDay: {
            type: "timeGrid",
            duration: {
              days: 2,
            },
            buttonText: this.translate("2 Day"),
          },
          dayGridMonthCustom: {
            type: "dayGrid",
            buttonText: this.translate("Month"),
            duration: {
              month: 1,
            },
          },
        },
        customButtons: {
          refreshButton: {
            text: this.translate("Refresh"),
            click: () => {
              this.refreshEvents();
            },
          },
        },
        headerToolbar: {
          start: "timeGridTwoDay dayGridMonthCustom",
          center: "",
          end: "refreshButton today prev,next",
        },
        nowIndicator: true,
        allDaySlot: false,
        editable: true,
        slotEventOverlap: false,
        displayEventTime: true,
        weekends: true,
        selectable: true,
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: true,
          hour12: false,
        },
        aspectRatio: 0.5,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        select: this.handleSelect,
        height: 650,
        eventClick: this.handleEventClick,
        locales: [frLocale],
        locale: "fr",
      },
      refreshTimer: 60000,
    };
  },
  inject: ["GStore"],
  computed: {
    ...mapGetters("project", ["unfinishedProjects"]),
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Home",
      });
      return href;
    },
  },
  mounted() {
    // this.autoRefresh();
  },
  methods: {
    ...mapActions("project", ["fetchUnfinishedProjects"]),
    ...mapActions("intervention", ["fetchIntervention"]),
    handleSelect(selectionInfo) {
      if (selectionInfo.view.type === "dayGridMonthCustom") {
        this.$refs.fullCalendar
          .getApi()
          .changeView("timeGridTwoDay", selectionInfo.startStr);
      } else {
        this.GStore.intervention = {
          projects: this.unfinishedProjects,
          project_id: this.unfinishedProjects.find(
            (project) => project.title === "Non-assigné"
          ).id,
          start: selectionInfo.startStr,
          end: selectionInfo.endStr,
        };
        this.$router.push({
          name: "InterventionCreateEdit",
          params: {
            formType: "Create",
            start: selectionInfo.startStr,
            end: selectionInfo.endStr,
          },
        });
      }
    },
    handleAddIntervClick() {
      let endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      this.GStore.intervention = {
        projects: this.unfinishedProjects,
        project_id: this.unfinishedProjects.find(
          (project) => project.title === "Non-assigné"
        ).id,
        start: new Date().toISOString(),
        end: endDate.toISOString(),
      };
      this.$router.push({
        name: "InterventionCreateEdit",
        params: {
          formType: "Create",
        },
      });
    },
    handleEventClick(eventinfo) {
      if (eventinfo.view.type === "dayGridMonthCustom") {
        this.$refs.fullCalendar
          .getApi()
          .changeView("timeGridTwoDay", eventinfo.event.startStr);
      } else {
        this.openModal(eventinfo.event.id);
      }
    },
    refreshEvents() {
      if (typeof this.$refs.fullCalendar !== "undefined")
        this.$refs.fullCalendar.getApi().refetchEvents();
    },
    autoRefresh: function () {
      setInterval(() => {
        this.refreshEvents();
      }, this.refreshTimer); //Refresh every 1 minute
    },
    async openModal(interventionId) {
      await this.fetchIntervention(interventionId);
      const modal = await modalController.create({
        component: SecondaryInfoModal,
        componentProps: {
          redirectedFrom: "calendar",
        },
      });
      await modal.present();
    },
  },
  async created() {
    await this.fetchUnfinishedProjects().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
  },
});
</script>

<style>
</style>
