<template>
  <base-layout
    :page-title="translate('Interventions')"
    :pageDefaultBackLink="backDefaultUrl"
    :showFooter="true"
  >
    <ion-refresher slot="fixed" @ionRefresh="refreshComponent($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        :pulling-text="translate('Pull to refresh')"
        refreshing-spinner="circles"
        :refreshing-text="translate('Refreshing') + '...'"
      >
      </ion-refresher-content>
    </ion-refresher>
    <intervention-list
      :interventions="showToday ? todayInterventions : tomorrowInterventions"
    ></intervention-list>
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddIntervention">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->
    <template v-slot:footerContent>
      <ion-toolbar class="footer">
        <ion-segment @ionChange="tabChange($event)" value="today">
          <ion-segment-button value="today">
            <ion-label>{{ translate("Today") }}</ion-label>
            <ion-label>{{ new Date().toLocaleDateString("fr-CH") }}</ion-label>
            <ion-badge
              style="position: absolute; right: 1px; top: 5px"
              v-if="isDataLoaded"
              color="warning"
              >{{ todayInterventions.length }}</ion-badge
            >
          </ion-segment-button>
          <ion-segment-button value="tomorrow">
            <ion-label>{{
              tomorrowIntervMonday ? translate("Monday") : translate("Tomorrow")
            }}</ion-label>
            <ion-label>{{ nextDate }}</ion-label>
            <ion-badge
              style="position: absolute; right: 1px; top: 5px"
              v-if="isDataLoaded"
              color="warning"
              >{{ tomorrowInterventions.length }}</ion-badge
            >
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "../../components/base/BaseLayout";
import {
  refresh,
  chevronDown,
  chevronDownCircleOutline,
  add,
} from "ionicons/icons";
import {
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonBadge,
  IonLabel,
  // IonFab,
  // IonFabButton,
  // IonIcon,
} from "@ionic/vue";
import InterventionList from "@/components/interventions/InterventionList.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      refresh,
      chevronDown,
      chevronDownCircleOutline,
      showToday: true,
      todayInterventions: [],
      tomorrowInterventions: [],
      add,
      tomorrowIntervMonday: false,
    };
  },
  components: {
    BaseLayout,
    InterventionList,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonBadge,
    IonLabel,
    // IonFab,
    // IonFabButton,
    // IonIcon,
  },
  inject: ["GStore"],
  computed: {
    // ...mapState("intervention", ["todayTomorrowInterventions"]),
    ...mapGetters("project", ["unfinishedProjects"]),
    ...mapGetters("intervention", [
      "todayTomorrowInterventionsNotCancelled",
      "mondayInterventions",
    ]),
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Home",
      });
      return href;
    },
    isDataLoaded() {
      return this.todayTomorrowInterventionsNotCancelled.length !== 0;
    },
    isFriday() {
      const today = new Date();
      return today.getDay() === 5;
    },
    nextDate() {
      const nextDate = new Date();
      let days;
      this.tomorrowIntervMonday ? (days = 3) : (days = 1);
      nextDate.setDate(nextDate.getDate() + days);
      return nextDate.toLocaleDateString("fr-CH");
    },
  },
  methods: {
    ...mapActions("intervention", [
      "fetchTodayTomorrowInterventions",
      "fetchMondayInterventions",
    ]),
    ...mapActions("task", ["fetchTaskCategories"]),
    ...mapActions("project", ["fetchUnfinishedProjects"]),
    async refreshComponent(event) {
      await this.fetchTodayTomorrowInterventions().catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
      if (this.isFriday) {
        await this.fetchMondayInterventions().catch((error) => {
          this.$router.push({
            name: "ErrorDisplay",
            params: {
              error,
            },
          });
        });
      }
      event.target.complete();
      this.updateInterventions();
    },
    tabChange(event) {
      const tabNameClicked = event.detail.value;
      if (tabNameClicked === "today") {
        this.showToday = true;
      } else {
        this.showToday = false;
      }
    },
    updateInterventions() {
      this.todayInterventions = [];
      this.tomorrowInterventions = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.todayTomorrowInterventionsNotCancelled.forEach((intervention) => {
        if (new Date(intervention.start) < tomorrow) {
          this.todayInterventions.push(intervention);
        } else {
          this.tomorrowInterventions.push(intervention);
        }
      });
      if (this.tomorrowInterventions.length === 0 && this.isFriday) {
        this.tomorrowInterventions = this.mondayInterventions;
        this.tomorrowIntervMonday = true;
      } else {
        this.tomorrowIntervMonday = false;
      }
    },
    handleAddIntervention() {
      let endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      this.GStore.intervention = {
        projects: this.unfinishedProjects,
        project_id: this.unfinishedProjects.find(
          (project) => project.title === "Non-assigné"
        ).id,
        start: new Date().toISOString(),
        end: endDate.toISOString(),
      };
      this.$router.push({
        name: "InterventionCreateEdit",
        params: {
          formType: "Create",
        },
      });
    },
  },
  async created() {
    await this.fetchTodayTomorrowInterventions().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
    await this.fetchTaskCategories().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
    await this.fetchUnfinishedProjects().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
    this.updateInterventions();
  },
  async ionViewDidEnter() {
    await this.fetchTodayTomorrowInterventions().catch((error) => {
      this.$router.push({
        name: "ErrorDisplay",
        params: {
          error,
        },
      });
    });
    if (this.isFriday) {
      await this.fetchMondayInterventions().catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
    }
    this.updateInterventions();
  },
};
</script>

<style scoped>
.footer {
  --background: #ffffff;
  --color: black;
}
</style>
