import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { home } from "./home.store";
import { intervention } from './intervention.store'
import { project } from './project.store'
import { task }  from './task.store'
import { tag }  from './tag.store'

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        home,
        intervention,
        task,
        project,
        tag
    },
})
