<template>
  <ion-list>
    <ion-item-group>
      <ion-item-divider color="warning">
        <ion-label>
          <strong
            >{{ translate("Intervention") }} {{ translate("Tags") }}</strong
          >
        </ion-label>
      </ion-item-divider>
      <ion-item color="medium">
        <ion-label class="ion-text-start"> {{ translate("Title") }} </ion-label>
        <ion-label class="ion-text-start">
          {{ translate("Importance") }}
        </ion-label>
      </ion-item>
      <tag-list-item
        v-for="(tag, index) in tags"
        :key="index"
        :tag="tag"
        :interventionId="interventionId"
        :tagIndex="index"
      >
      </tag-list-item>
    </ion-item-group>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import TagListItem from "./ListItem.vue";
export default {
  name: "TagList",
  components: {
    IonList,
    TagListItem,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
    IonItem,
  },
  props: {
    tags: Array,
  },
};
</script>

<style>
</style>