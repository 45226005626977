<template>
  <base-layout
    :pageTitle="
      translate('Intervention') +
      ' ' +
      translate('Details') +
      ': ' +
      currentIntervention.title
    "
    :pageDefaultBackLink="backDefaultUrl"
  >
    <ion-refresher slot="fixed" @ionRefresh="refreshComponent($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        :pulling-text="translate('Pull to refresh')"
        refreshing-spinner="circles"
        :refreshing-text="translate('Refreshing') + '...'"
      >
      </ion-refresher-content>
    </ion-refresher>
    <ion-content class="ion-padding">
      <ion-item-divider :sticky="true" color="warning">
        <ion-label
          ><strong>
            {{ translate("Tasks") + " " + translate("Completed") }}
          </strong>
        </ion-label>
      </ion-item-divider>
      <completed-tasks-list-item
        v-for="(completedTask, index) in completedTasks"
        :key="index"
        :completedTask="completedTask"
      ></completed-tasks-list-item>
    </ion-content>
  </base-layout>
</template>

<script>
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonLabel,
  IonItemDivider,
} from "@ionic/vue";
import CompletedTasksListItem from "@/components/tasks/CompletedTaskListItem.vue";
import BaseLayout from "@/components/base/BaseLayout.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { chevronDownCircleOutline } from "ionicons/icons";

export default {
  components: {
    CompletedTasksListItem,
    IonContent,
    BaseLayout,
    IonRefresher,
    IonRefresherContent,
    IonLabel,
    IonItemDivider,
  },
  data() {
    return {
      chevronDownCircleOutline,
    };
  },
  props: ["projectId"],
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
    ...mapGetters("task", ["completedTasks"]),
    pageTitle() {
      return this.translate("Tasks");
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "InterventionTabsRoute",
        params: { id: this.currentIntervention.id },
      });
      return href;
    },
  },
  methods: {
    ...mapActions("task", ["fetchTaskHistory"]),
    async refreshComponent(event) {
      await this.fetchTaskHistory(this.projectId).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
      event.target.complete();
    },
  },
  created() {
    this.fetchTaskHistory(this.projectId).catch((error) => {
      this.$router.push({ name: "ErrorDisplay", params: { error } });
    });
  },
};
</script>

<style>
</style>