<template>
  <ion-content class="ion-padding">
    <h4>{{ translate("Intervention") + " " + translate("Process") }} :</h4>
    <ion-button
      expand="block"
      @click="handleClick"
      :disabled="
        status === 'returned' ||
        futureIntervention ||
        (currentIntervention.project.category === 'unproductive' &&
          currentIntervention.project.title !== 'Maintenance')
      "
      >{{ buttonText }}</ion-button
    >
    <h4>{{ translate("Location") + " " + translate("list") }} :</h4>
    <location-list :locations="locations"></location-list>
    <div v-if="status === 'returned'">
      <h4>TS de l'intervention :</h4>
      <div v-if="hasTS">
        <p>
          Description : {{ currentInterventionData.extra_hours_description }}
        </p>
        <p>Durée : {{ currentInterventionData.extra_hours_time }}</p>
      </div>
      <div v-else>
        <p>{{ translate("noTsForThisIntervention") }}</p>
        <ion-button @click="presentExtraHourModal()">
          {{ translate("Add") }} TS
        </ion-button>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonButton,
  toastController,
  alertController,
  modalController,
} from "@ionic/vue";
import { mapState, mapActions, mapGetters } from "vuex";
import InterventionService from "@/services/intervention.service";
import ProjectService from "@/services/project.service";
import LocationList from "@/components/interventions/location/List";
import ExtraHourModal from "@/components/interventions/ExtraHourModal.vue";
export default {
  components: { IonContent, IonButton, LocationList },
  data() {
    return {
      interventionData: {},
    };
  },
  props: {
    interventionId: String,
  },
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
    ...mapGetters("intervention", ["currentInterventionData"]),
    ...mapGetters("intervention", ["todayTomorrowInterventionsNotCancelled"]),
    status() {
      if (this.currentIntervention.validated_arrival !== null) {
        return "returned";
      } else if (!this.currentIntervention.confirmed) {
        return "not_confirmed";
      } else if (this.currentIntervention.departure === null) {
        return "at_garage";
      } else if (this.currentIntervention.on_site === null) {
        return "departured";
      } else if (this.currentIntervention.finished === null) {
        return "working";
      } else if (this.currentIntervention.arrival === null) {
        return "returning";
      } else {
        return "returned";
      }
    },
    buttonText() {
      if (this.status === "not_confirmed") {
        return this.translate("CONFIRM INTERVENTION");
      } else if (this.status === "at_garage") {
        return this.translate("GARAGE DEPARTURE");
      } else if (this.status === "departured") {
        return this.translate("ON SITE");
      } else if (this.status === "working") {
        return this.translate("FINISHED");
      } else {
        return this.translate("GARAGE ARRIVAL");
      }
    },
    locations() {
      let res = [];
      switch (this.status) {
        case "returned":
          res.push({
            time: this.interventionData.arrival,
            point: this.currentIntervention.arrival_location,
            name: "Arrival",
          });
        // falls through
        case "returning":
          res.push({
            time: this.interventionData.finished,
            point: this.currentIntervention.finish_location,
            name: "Finished",
          });
        // falls through
        case "working":
          res.push({
            time: this.interventionData.on_site,
            point: this.currentIntervention.onsite_location,
            name: "On site",
          });
        // falls through
        case "departured":
          res.push({
            time: this.interventionData.departure,
            point: this.currentIntervention.departure_location,
            name: "Departure",
          });
        // falls through
        default:
          break;
      }
      return res;
    },
    todayInterventions() {
      let jobs = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.todayTomorrowInterventionsNotCancelled.forEach((intervention) => {
        if (new Date(intervention.start) < tomorrow) {
          jobs.push(intervention);
        }
      });
      return jobs;
    },
    futureIntervention() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let currentdate = new Date(this.currentIntervention.start);
      currentdate.setHours(0, 0, 0, 0);

      if (currentdate > today) return true;
      return false;
    },
    hasTS() {
      return (
        (this.currentInterventionData.extra_hours_description !== null &&
          this.currentInterventionData.extra_hours_description !== "") ||
        (this.currentInterventionData.extra_hours_time !== null &&
          this.currentInterventionData.extra_hours_time !== "0.00")
      );
    },
  },
  methods: {
    ...mapActions("intervention", [
      "fetchIntervention",
      "fetchTodayTomorrowInterventions",
    ]),
    async handleClick() {
      let response = "failed";
      if (this.status === "not_confirmed") {
        await InterventionService.confirmIntervention(
          this.currentIntervention.id
        )
          .then(async () =>
            this.presentToast(true, this.translate("Intervention confirmed!"))
          )
          .catch(async (error) => this.presentToast(false, error));
      } else if (this.status === "at_garage") {
        const result = this.todayInterventions.find(
          (todayIntervention) =>
            todayIntervention.departure !== null &&
            (todayIntervention.validated_arrival === null ||
              todayIntervention.arrival === null) &&
            todayIntervention.id != this.currentIntervention.id
        );

        if (result)
          this.presentToast(
            false,
            "Vous ne pouvez pas démarrer cette intervention, l'intervention -> " +
              result.id +
              " est toujours en cours"
          );
        else await this.sendPosition("garage");
      } else if (this.status === "departured") {
        await this.sendPosition("on_site");
      } else if (this.status === "working") {
        response = await this.sendPosition("finished");
        if (response === "success") await this.presentExtraHoursAlert();
      } else if (this.status === "returning") {
        response = await this.sendPosition("arrival");
        if (response === "success") await this.presentFinishProjectAlert();
      }
      this.refreshIntervention();
    },
    position() {
      return new Promise((resolve, reject) => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                hour: this.getCurrentDateTime(),
              });
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                hour: this.getCurrentDateTime(),
              });
            });
          } else {
            reject("Veuillez activer la géolocalisation pour continuer");
          }
        });
      });
    },
    async refreshIntervention() {
      this.fetchIntervention(this.currentIntervention.id).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
      this.getInterventionDetails();
    },
    async presentFinishProjectAlert() {
      if (
        this.currentIntervention.project.category === "unproductive" &&
        this.currentIntervention.project.title !== "Non-assigné"
      )
        return;
      const alert = await alertController.create({
        header: this.translate("Project") + " " + this.translate("Status"),
        message:
          "<strong>" +
          this.translate("Is this project finished?") +
          "</strong>",
        buttons: [
          {
            text: this.translate("No"),
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: this.translate("Yes"),
            role: "ok",
            cssClass: "tertiary",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (this.currentInterventionData.project.title == "Non-assigné") {
        await InterventionService.setIfProjectIsFinished(
          this.currentIntervention.id,
          role === "ok"
        ).catch(async (error) => {
          const toast = await toastController.create({
            message: error,
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
      } else {
        let projectStatus = new FormData();
        if (role === "ok") {
          projectStatus.append("status", "finished");
          await ProjectService.setProjectStatus(
            this.currentIntervention.project_id,
            projectStatus
          )
            .then(async () => {
              const toast = await toastController.create({
                message: this.translate("The project was updated to finished"),
                position: "bottom",
                duration: 4000,
                color: "success",
              });
              await toast.present();
            })
            .catch(async (error) => {
              const toast = await toastController.create({
                message: error,
                position: "bottom",
                duration: 4000,
                color: "danger",
              });
              await toast.present();
            });
        } else {
          projectStatus.append("status", "in_process");
          await ProjectService.setProjectStatus(
            this.currentIntervention.project_id,
            projectStatus
          )
            .then(async () => {
              const toast = await toastController.create({
                message: this.translate(
                  "The project was updated to in process"
                ),
                position: "bottom",
                duration: 4000,
                color: "success",
              });
              await toast.present();
            })
            .catch(async (error) => {
              const toast = await toastController.create({
                message: error,
                position: "bottom",
                duration: 4000,
                color: "danger",
              });
              await toast.present();
            });
        }
      }
    },
    async presentExtraHoursAlert() {
      const alert = await alertController.create({
        header: this.translate("Extra work hours"),
        message:
          "<strong>" + this.translate("Did you have extra work?") + "</strong>",
        buttons: [
          {
            text: this.translate("No"),
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: this.translate("Yes"),
            role: "ok",
            cssClass: "tertiary",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === "ok") {
        await this.presentExtraHourModal();
      }
    },
    async presentExtraHourModal() {
      const modal = await modalController.create({
        component: ExtraHourModal,
        componentProps: {
          interventionId: this.currentIntervention.id,
        },
      });
      await modal.present();
    },
    async presentToast(success, message) {
      const toast = await toastController.create({
        message: message,
        position: "botton",
        duration: "3000",
        color: success ? "success" : "danger",
      });
      await toast.present();
    },
    async sendPosition(location) {
      let currentPosition = {};
      try {
        currentPosition = await this.position();
      } catch (error) {
        await this.presentToast(false, error);
        return "failed";
      }
      await InterventionService.sendPosition(
        location,
        this.currentIntervention.id,
        currentPosition
      )
        .then(async () =>
          this.presentToast(true, this.translate("Location sent!"))
        )
        .catch(async (error) => this.presentToast(false, error));
      await this.fetchTodayTomorrowInterventions().catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
      return "success";
    },
    async getInterventionDetails() {
      await InterventionService.getInterventionDetails(this.interventionId)
        .then((response) => {
          this.interventionData = JSON.parse(JSON.stringify(response.data));
        })
        .catch((error) => {
          throw error;
        });
    },
    getCurrentDateTime() {
      const currentdate = new Date();
      const datetime =
        String(currentdate.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(currentdate.getDate()).padStart(2, "0") +
        "/" +
        currentdate.getFullYear() +
        " " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes();

      return datetime;
    },
  },
  watch: {
    interventionId: function (val) {
      if (val) {
        this.getInterventionDetails();
      }
    },
  },
  created() {
    this.getInterventionDetails();
  },
};
</script>

<style></style>
