<template>
  <ion-card class="transparent">
    <img class="avatar" />
    <ion-card-header>
      <ion-card-title class="ion-text-center">{{
        profileData.name
      }}</ion-card-title>
      <ion-card-subtitle class="ion-text-center">{{
        profileData.role
      }}</ion-card-subtitle>
    </ion-card-header>
  </ion-card>
  <ion-list>
    <ion-list-header lines="full" class="custom-font-size">
      {{ translate("System Configuration") }}
    </ion-list-header>
    <ion-item>
      <ion-icon :icon="mail" color="primary"></ion-icon>
      <ion-label class="ion-padding-start">{{ profileData.email }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon :icon="language" color="primary"></ion-icon>
      <ion-label class="ion-padding-start">{{
        profileData.language == "fr" ? "Français" : "English"
      }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon :icon="time" color="primary"></ion-icon>
      <ion-label class="ion-padding-start">{{
        profileData.timezone
      }}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonListHeader,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import { mail, language, time } from "ionicons/icons";
export default {
  data() {
    return {
      mail,
      language,
      time,
    };
  },
  props: {
    profileData: Object,
  },
  components: {
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonListHeader,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
};
</script>

<style scoped>
.avatar {
  border: 8px solid #6bbef5;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.custom-font-size {
  font-size: 1.5rem;
  font-weight: bold;
}
.transparent {
  background-color: #ffffff;
}
</style>