<template>
  <base-layout
    :pageDefaultBackLink="backDefaultUrl"
    :pageTitle="translate('Project Details') + ': ' + currentProject.title"
    :showFooter="true"
  >
    <project-overview
      :project="currentProject"
      v-if="showInfo"
    ></project-overview>
    <doc-list
      :docs="[]"
      :projectDocs="currentProject.documents"
      v-if="showDocuments"
    ></doc-list>
    <intervention-list
      v-if="showInterventions"
      :interventions="currentProject.jobs"
    ></intervention-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddIntervention">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <template v-slot:footerContent>
      <ion-toolbar class="footer">
        <ion-segment @ionChange="tabChange($event)" value="info">
          <ion-segment-button value="info">
            <ion-icon :icon="informationCircle"></ion-icon>
            <ion-label>{{ translate("Details") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="interventions"
            ><ion-icon :icon="hammer"></ion-icon>
            <ion-label>{{ translate("Interventions") }}</ion-label>
            <ion-badge class="badge" color="warning" v-if="isDataLoaded">{{
              interventionsCount
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button value="documents">
            <ion-icon :icon="document"></ion-icon>
            <ion-label>{{ translate("Docs") }}</ion-label>
            <ion-badge class="badge" color="warning" v-if="isDataLoaded">{{
              documentsCount
            }}</ion-badge>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/base/BaseLayout.vue";
import ProjectOverview from "@/components/projects/ProjectOverview.vue";
import DocList from "@/components/documents/List.vue";
import InterventionList from "@/components/interventions/InterventionList.vue";
import { add, informationCircle, document, hammer } from "ionicons/icons";
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonBadge,
  IonLabel,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { watchEffect } from "vue";
export default {
  props: ["id"],
  components: {
    IonFab,
    IonFabButton,
    BaseLayout,
    ProjectOverview,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonToolbar,
    IonBadge,
    DocList,
    IonLabel,
    InterventionList,
  },
  data() {
    return {
      add,
      informationCircle,
      document,
      hammer,
      showInfo: true,
      showDocuments: false,
      showInterventions: false,
    };
  },
  inject: ["GStore"],
  methods: {
    ...mapActions("project", ["fetchCurrentProject"]),
    handleAddIntervention() {
      let endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      this.GStore.intervention = {
        projects: [this.currentProject],
        project_id: this.currentProject.id,
        start: new Date().toISOString(),
        end: endDate.toISOString(),
      };
      this.$router.push({
        name: "InterventionCreateEdit",
        params: {
          formType: "Create",
        },
      });
    },
    tabChange(event) {
      const tabNameClicked = event.detail.value;
      this.showInfo = this.showInterventions = this.showDocuments = false;
      switch (tabNameClicked) {
        case "info":
          this.showInfo = true;
          break;
        case "documents":
          this.showDocuments = true;
          break;
        case "interventions":
          this.showInterventions = true;
          break;
        default:
          this.showInfo = true;
          break;
      }
    },
  },
  computed: {
    ...mapGetters("project", ["currentProject"]),
    isDataLoaded() {
      return Object.keys(this.currentProject).length !== 0;
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({ name: "Projects" });
      return href;
    },
    documentsCount() {
      return this.currentProject.documents.filter(
        (doc) => doc.access_permission !== "administrator"
      ).length;
    },
    interventionsCount() {
      return this.currentProject.jobs.length;
    },
  },
  async created() {
    watchEffect(async () => {
      await this.fetchCurrentProject(this.id).catch((error) => {
        this.$router.push({
          name: "ErrorDisplay",
          params: {
            error,
          },
        });
      });
    });
  },
};
</script>

<style scoped>
.footer {
  --background: #ffffff;
  --color: black;
}
.badge {
  position: absolute;
  right: 1px;
  top: 5px;
}
</style>
