<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label>{{ translate("Product") }}</ion-label>
        <!-- <ion-select
          v-model="GStore.product.product_id"
          :placeholder="translate('Select') + ' ' + translate('Product')"
          v-if="isDataLoaded"
          :disabled="formType === 'Edit'"
          interface="action-sheet"
        >
          <ion-label>{{ translate("Product") }}</ion-label>
          <ion-select-option
            v-for="product of products"
            :key="product.id"
            :value="product.id"
            >{{ product.name }}</ion-select-option
          >
        </ion-select> -->
        <ion-label>{{ productName }}</ion-label>
        <ion-label @click="openModal" color="primary">{{
          translate("Select")
        }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="fixed">{{ translate("Quantity") }}</ion-label>
        <ion-input
          type="number"
          v-model="GStore.product.qty"
          :placeholder="translate('Used') + ' ' + translate('Product')"
          min="1"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ translate("Comment") }}</ion-label>
        <ion-textarea
          :placeholder="translate('Comment')"
          v-model="GStore.product.comment"
        ></ion-textarea>
      </ion-item>
      <ion-list>
        <ion-radio-group v-model="GStore.product.ts">
          <ion-list-header>
            <ion-label
              ><strong>{{ translate("typeOfMaterial") }} :</strong></ion-label
            >
          </ion-list-header>
          <ion-item class="radioBorder">
            <ion-label>{{ translate("normalMaterial") }}</ion-label>
            <ion-radio slot="start" :value="0"></ion-radio>
          </ion-item>
          <ion-item class="radioBorder">
            <ion-label>{{ translate("tsMaterial") }}</ion-label>
            <ion-radio slot="start" :value="1"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-list>
    <ion-button @click="$router.go(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import ProductService from "@/services/product.service";
import {
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonTextarea,
  toastController,
  modalController,
  IonRadioGroup,
  IonRadio,
} from "@ionic/vue";
import SearchableSelect from "@/components/searchableSelect/searchableSelectList.vue";
export default {
  name: "AddEditProductForm",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
    projectProduct: {
      type: String,
      default: "false",
    },
  },
  components: {
    IonLabel,
    IonList,
    IonListHeader,
    IonItem,
    IonInput,
    IonButton,
    IonTextarea,
    IonRadioGroup,
    IonRadio,
  },
  data() {
    return {
      products: [],
    };
  },
  inject: ["GStore"],
  computed: {
    createForm() {
      return Object.keys(this.GStore.product).length === 0;
    },
    isDataLoaded() {
      return this.products.length !== 0;
    },
    productName() {
      let name = "";
      this.products.forEach((element) => {
        if (this.GStore.product.product_id == element.id) name = element.name;
      });
      return name;
    },
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async submitForm() {
      if (!this.GStore.product.product_id) {
        const toast = await toastController.create({
          message: this.translate("pleaseSelectAProduct"),
          position: "bottom",
          duration: 3000,
          color: "danger",
        });
        await toast.present();
        return;
      }
      if (this.GStore.product.ts === undefined) {
        const toast = await toastController.create({
          message: this.translate("pleaseSelectTypeOfMaterial"),
          position: "bottom",
          duration: 3000,
          color: "danger",
        });
        await toast.present();
        return;
      }
      let serviceFunctionName =
        this.formType === "Create"
          ? "createProductTransaction"
          : "updateProductTransaction";
      if (this.projectProduct == "true") {
        serviceFunctionName = "createProductTransaction";
      }
      await ProductService[serviceFunctionName](this.GStore.product)
        .then(async () => {
          let message =
            this.formType === "Create"
              ? "theProductWasAdded"
              : "theProductWasUpdated";
          const toast = await toastController.create({
            message: this.translate(message),
            position: "bottom",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          this.fetchIntervention(this.GStore.product.job_id).catch((error) => {
            this.$router.push({ name: "ErrorDisplay", params: { error } });
          });
          this.$router.replace({
            name: "InterventionTabsRoute",
            params: { id: this.GStore.product.job_id },
          });
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
    async openModal() {
      const modal = await modalController.create({
        component: SearchableSelect,
        componentProps: {
          selectType: "products",
          items: this.products,
        },
      });
      await modal.present();
      await modal.onDidDismiss().then((data) => {
        this.GStore.product.product_id = data.data;
      });
    },
  },
  async created() {
    if (this.formType === "Create") {
      await ProductService.getUnsedUnlockedProducts(this.GStore.product.job_id)
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          this.$router.push({ name: "ErrorDisplay", params: { error } });
        });
    } else if (this.formType === "Update") {
      await ProductService.getProducts()
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          this.$router.push({ name: "ErrorDisplay", params: { error } });
        });
    }
  },
};
</script>

<style>
.radioBorder {
  margin-bottom: 3px;
  border: 1px solid #b8b7b7;
  border-radius: 5px;
}
</style>