<template>
  <ion-card class="custom-ioncard">
    <ion-card-title>
      <strong>{{ project.title }}</strong>
    </ion-card-title>
    <ion-card-content>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="location" color="danger" size="large"></ion-icon>
        <ion-label style="padding-left: 10px">
          <h2>{{ project.street_name }}</h2>
          <p>{{ project.postal_code }}</p>
          <p v-if="project.floor != null">
            {{ translate("Floor") + " : " + project.floor }}
          </p>
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon
          :icon="constructOutline"
          color="danger"
          size="large"
        ></ion-icon>
        <ion-label class="ion-padding ion-text-wrap">
          {{ project.task_description }}
        </ion-label>
      </ion-item>
      <ion-item
        v-if="project.client != null"
        class="item-card"
        background="warning"
      >
        <ion-icon :icon="person" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ "Client: " + project.client.company }}
        </ion-label>
      </ion-item>
      <ion-item
        v-if="project.owner != null"
        class="item-card"
        background="warning"
      >
        <ion-icon :icon="person" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ translate("Owner") + ": " + project.owner.name }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="location" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ "Place type: " + translate(project.place_type) }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="person" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ translate("Tenant") + ": " + checkNullValue(project.tenant) }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="call" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{
            translate("Tenant Phone") +
              ": " +
              checkNullValue(project.tenant_phone_one)
          }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="person" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ translate("Contact") + ": " + checkNullValue(project.contact) }}
        </ion-label>
      </ion-item>
      <ion-item class="item-card" background="warning">
        <ion-icon :icon="call" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{
            translate("Contact phone") +
              ": " +
              checkNullValue(project.contact_phone)
          }}
        </ion-label>
      </ion-item>
      <ion-item
        v-if="project.order_number != null"
        class="item-card"
        background="warning"
      >
        <ion-icon :icon="document" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ translate("Order number") + ": " + project.order_number }}
        </ion-label>
      </ion-item>
      <ion-item
        v-if="project.quote_number != null"
        class="item-card"
        background="warning"
      >
        <ion-icon :icon="document" color="danger" size="large"></ion-icon>
        <ion-label class="ion-padding">
          {{ translate("Quotation number") + ": " + project.quote_number }}
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button @click="handleAddIntervention">
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import {
  person,
  carOutline,
  constructOutline,
  call,
  location,
  document,
  add,
} from "ionicons/icons";

export default {
  name: "ProjectOverview",
  props: {
    project: {
      type: Object,
    },
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonIcon,
    IonFab,
    IonFabButton,
  },
  data() {
    return {
      person,
      carOutline,
      constructOutline,
      call,
      location,
      document,
      add,
    };
  },
  methods: {
    checkNullValue(value) {
      return value ?? "-";
    },
    handleAddIntervention() {
      let endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      this.GStore.intervention = {
        projects: [{ ...this.project }],
        project_id: this.project.id,
        start: new Date().toISOString(),
        end: endDate.toISOString(),
      };
      this.$router.push({
        name: "InterventionCreateEdit",
        params: {
          formType: "Create",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom-ioncard {
  padding: 10px;
  background: linear-gradient(
    to left,
    rgba(250, 0, 0, 0.3),
    rgba(255, 0, 0, 0.8)
  );
}

.item-card {
  border-radius: 10px 10px 10px 10px;
  margin: 5px;
}
</style>
