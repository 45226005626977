<template>
  <base-layout :pageTitle="pageTitle" :pageDefaultBackLink="backDefaultUrl">
    <add-photo-form v-if="formType === 'Create'"></add-photo-form>
    <edit-photo-form v-if="formType !== 'Create'"></edit-photo-form>
  </base-layout>
</template>

<script>
import AddPhotoForm from "@/components/photos/AddForm";
import EditPhotoForm from "@/components/photos/EditForm";
import BaseLayout from "@/components/base/BaseLayout.vue";
export default {
  components: { BaseLayout, AddPhotoForm, EditPhotoForm },
  name: "AddEditPhoto",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
  },
  computed: {
    pageTitle() {
      return this.translate(this.formType) + " " + this.translate("Photo");
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({ name: "Interventions" });
      return href;
    },
  },
};
</script>

<style>
</style>