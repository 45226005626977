<template>
  <base-layout :pageTitle="pageTitle" :pageDefaultBackLink="backDefaultUrl">
    <!-- <add-edit-task-form @save-task="saveTask"></add-edit-task-form> -->
    <add-edit-intervention-form
      :formType="formType"
    ></add-edit-intervention-form>
  </base-layout>
</template>

<script>
import AddEditInterventionForm from "@/components/interventions/AddEdit";
import BaseLayout from "@/components/base/BaseLayout.vue";
export default {
  components: {
    BaseLayout,
    AddEditInterventionForm,
  },
  name: "AddEditIntervention",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
  },
  computed: {
    pageTitle() {
      return (
        this.translate(this.formType) + " " + this.translate("Intervention")
      );
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Interventions",
      });
      return href;
    },
  },
  methods: {},
};
</script>

<style>
</style>
