import TagService from "@/services/tag.service";

const state = {
    tags: []
}

const getters = {
    tags: (state) => {
        return state.tags
    }
}

const mutations = {
    SET_INTERVENTION_TAGS(state, tags) {
        state.tags = tags
    }
}

const actions = {
    fetchInterventionTags({ commit }, interventionId) {
        TagService.getTags(interventionId)
            .then(response => {
                commit('SET_INTERVENTION_TAGS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
}

export const tag = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}