<template>
  <ion-item-sliding>
    <!--:disabled="disableSliding" used to disable sliding-->
    <ion-item-options>
      <ion-item-option
        color="danger"
        @click="plannedTask ? openTaskDescriptionModal('cancelled') : ''"
      >
        {{ plannedTask ? translate("Cancel") : "" }}
      </ion-item-option>
      <ion-item-option
        :color="plannedTask ? 'success' : 'danger'"
        @click="
          plannedTask
            ? openTaskDescriptionModal('completed')
            : presentDeleteAlert()
        "
      >
        {{ plannedTask ? translate("Completed") : translate("Delete") }}
      </ion-item-option>
      <ion-item-option color="warning" @click="handleEdit">
        {{ plannedTask ? translate("Edit and Add") : translate("Update") }}
      </ion-item-option>
    </ion-item-options>
    <ion-item
      class=""
      v-if="isDataLoaded"
      @click="openModal()"
      :style="{ color: quantityColor }"
    >
      <ion-label>
        {{ task.quantity }}
      </ion-label>
      <ion-label class="ion-text-wrap">
        <ion-text>
          {{
            plannedTask
              ? taskCategories.find(
                  (category) => category.id === task.task_category_id
                ).name
              : task.task_category.name
          }}
        </ion-text>
      </ion-label>
      <ion-icon
        v-if="plannedTask"
        slot="end"
        :icon="disableSliding ? checkmarkCircleOutline : closeCircleOutline"
        :color="disableSliding ? 'success' : 'danger'"
      >
      </ion-icon>
    </ion-item>
  </ion-item-sliding>
</template>

<script>
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import {
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  alertController,
  toastController,
  IonText,
  modalController,
  IonIcon,
} from "@ionic/vue";
import TaskService from "@/services/task.service";
import { mapState, mapActions, mapGetters } from "vuex";
import TaskModal from "./Modal";
import TaskDescriptionModal from "./TaskDescriptionModal.vue";

export default {
  props: {
    task: {
      type: Object,
    },
    itemClass: {
      type: String,
      default: "row0",
    },
    plannedTask: {
      type: Boolean,
    },
    projectTask: {
      type: Boolean
    },
    disableSliding: {
      type: Boolean,
      default: false,
    },
    quantityColor: {
      type: String,
      default: "black",
    },
  },
  inject: ["GStore"],
  components: {
    IonItemSliding,
    IonItem,
    IonItemOptions,
    IonItemOption,
    IonLabel,
    IonText,
    IonIcon,
  },
  data() {
    return {
      checkmarkCircleOutline,
      closeCircleOutline,
    };
  },
  computed: {
    ...mapState("task", ["taskCategories"]),
    ...mapGetters("intervention", ["currentInterventionData"]),
    isDataLoaded() {
      return this.taskCategories.length !== 0;
    },
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async addToCompletedTasks() {
      await TaskService.addAsCompletedTask(
        this.task.id,
        this.currentInterventionData.id
      )
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The task was added as completed"),
            position: "botton",
            duration: "3000",
            color: "success",
          });
          await toast.present();
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
      this.fetchIntervention(this.currentInterventionData.id).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
    },
    async presentDeleteAlert() {
      const alert = await alertController.create({
        header: this.translate("Delete") + " " + this.translate("Task"),
        message:
          "<strong>" +
          this.translate("Are you sure to delete this Task?") +
          "</strong>",
        buttons: [
          {
            text: this.translate("Cancel"),
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: this.translate("Delete"),
            role: "ok",
            cssClass: "danger",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === "ok") {
        await TaskService.deleteTask(this.task.id)
          .then(async () => {
            const toast = await toastController.create({
              message:
                this.translate("Task") + " was " + this.translate("deleted"),
              position: "bottom",
              duration: 4000,
              color: "success",
            });
            await toast.present();
            this.fetchIntervention(this.currentInterventionData.id).catch(
              (error) => {
                this.$router.push({ name: "ErrorDisplay", params: { error } });
              }
            );
          })
          .catch(async (error) => {
            const toast = await toastController.create({
              message: error,
              position: "bottom",
              duration: 4000,
              color: "danger",
            });
            await toast.present();
          });
      }
    },
    handleEdit() {
      this.GStore.task = { ...this.task };
      if (this.projectTask) {
        this.GStore.task.taskable_id = this.currentInterventionData.id;
      }
      this.$router.push({
        name: "TaskCreateEdit",
        params: {plannedTask: this.projectTask, formType: "Update" },
      });
    },
    async openModal() {
      const modal = await modalController.create({
        component: TaskModal,
        componentProps: {
          id: this.task.id,
          quantity: this.task.quantity,
          category: this.projectTask
            ? this.taskCategories.find(
                (category) => category.id === this.task.task_category_id
              ).name
            : this.task.task_category.name,
          description: this.task.new_description,
          technicianDescription: this.task.technician_description,
        },
      });
      await modal.present();
    },
    async openTaskDescriptionModal(flag) {
      const modal = await modalController.create({
        component: TaskDescriptionModal,
        componentProps: {
          taskId: this.task.id,
          interventionId: this.currentInterventionData.id,
          flag: flag,
        },
      });
      await modal.present();
    },
  },
};
</script>

<style>
.row1 {
  --background: var(--ion-color-light);
}

.row0 {
  --background: #ffffff;
}
</style>