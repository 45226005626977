import TaskService from "@/services/task.service";

const state = {
    taskCategories: [],
    completedTasks: [],
}

const getters = {
    taskCategories: (state) => {
        return state.taskCategories
    },
    completedTasks: (state) => {
        return state.completedTasks
    }
}

const mutations = {
    SET_TASK_CATEGORIES(state, taskCategories) {
        state.taskCategories = taskCategories
    },
    SET_COMPLETED_TASKS(state, completedTasks) {
        state.completedTasks = completedTasks
    }
}

const actions = {
    fetchTaskCategories({ commit }) {
        TaskService.getTaskCategories()
            .then(response => {
                commit('SET_TASK_CATEGORIES', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
    fetchTaskHistory({ commit }, projectId) {
        TaskService.getTaskHistory(projectId)
            .then(response => {
                commit('SET_COMPLETED_TASKS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
}

export const task = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}