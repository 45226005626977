<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label>{{ translate("Photo") }}</ion-label>
        <input type="file" @change="getFile" accept="image/*" multiple/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{
          translate("Description")
        }}</ion-label>
        <ion-textarea
          :placeholder="translate('Description')"
          v-model="GStore.photo.description"
        ></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button @click="$router.go(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import PhotoService from "@/services/photo.service";
import {
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonTextarea,
  toastController,
} from "@ionic/vue";
export default {
  name: "AddPhotoForm",
  props: {},
  components: {
    IonLabel,
    IonList,
    IonItem,
    IonButton,
    IonTextarea,
  },
  inject: ["GStore"],
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    getFile(event) {
      this.GStore.photo.images = event.target.files;
    },
    async submitForm() {
      if ("before_id" in this.GStore.photo) {
        this.GStore.photo.type = "after";
      } else {
        this.GStore.photo.type = "before";
        this.GStore.photo.before_id = null;
      }
      for (let i = 0; i < this.GStore.photo.images.length; i++) {
        console.log(`photo numero ${i}`)
        await PhotoService.uploadPhoto(this.GStore.photo, this.GStore.photo.images[i])
          .then(async () => {
            const toast = await toastController.create({
              message: this.translate("The photo was uploaded"),
              position: "bottom",
              duration: 3000,
              color: "success",
            });
            await toast.present();
            this.fetchIntervention(this.GStore.photo.job_id).catch((error) => {
              this.$router.push({ name: "ErrorDisplay", params: { error } });
            });
            this.$router.replace({
              name: "InterventionTabsRoute",
              params: { id: this.GStore.photo.job_id },
            });
          })
          .catch(async (error) => {
            const toast = await toastController.create({
              message: error,
              position: "bottom",
              duration: 3000,
              color: "danger",
            });
            await toast.present();
            this.$router.replace({
              name: "InterventionTabsRoute",
              params: { id: this.GStore.photo.job_id },
            });
          });
      }
    },
  },
};
</script>


<style>
</style>