<template>
  <base-layout
    :page-title="translate('Profile')"
    :pageDefaultBackLink="backDefaultUrl"
    :showFooter="true"
  >
    <settings
      v-if="showSettings"
      :profileData="profileData"
      :key="profileKey"
    ></settings>
    <employee-details
      v-if="!showSettings"
      :profileData="profileData"
      :key="profileKey"
    ></employee-details>
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="editSettings">
        <ion-icon :icon="pencil"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->
    <template v-slot:footerContent>
      <ion-toolbar class="footer">
        <ion-segment @ionChange="segmentChanged($event)" value="settings">
          <ion-segment-button value="settings">
            <ion-label>{{ translate("Settings") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button>
            <ion-label>{{ translate("Details") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "../../components/base/BaseLayout";
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  // IonFab,
  // IonFabButton,
  // IonIcon,
} from "@ionic/vue";
import Settings from "@/components/profile/Settings.vue";
import EmployeeDetails from "@/components/profile/EmployeeDetails.vue";
import { pencil } from "ionicons/icons";
import UserService from "@/services/user.service";
import { watchEffect } from "vue";
export default {
  data() {
    return {
      showSettings: true,
      pencil,
      profileData: {},
    };
  },
  inject: ["GStore", "LngGStore"],
  props: ["profileKey"],
  components: {
    BaseLayout,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonToolbar,
    Settings,
    // IonFab,
    // IonFabButton,
    EmployeeDetails,
    // IonIcon,
  },
  computed: {
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "Home",
      });
      return href;
    },
  },
  methods: {
    segmentChanged(event) {
      const segmentNameClicked = event.detail.value;
      if (segmentNameClicked === "settings") {
        this.showSettings = true;
      } else {
        this.showSettings = false;
      }
    },
    editSettings() {
      this.GStore.profile = { ...this.profileData };
      this.$router.push({
        name: "ProfileEdit",
      });
    },
  },
  created() {
    watchEffect(() => {
      UserService.getInfo(this.profileKey)
        .then((response) => {
          this.profileData = response.data;
          // this.lng = this.profileData.language
          this.LngGStore.setLanguage = this.profileData.language;
        })
        .catch((error) =>
          this.$router.push({ name: "ErrorDisplay", params: { error } })
        );
    });
  },
};
</script>

<style scoped>
.footer {
  --background: #ffffff;
}
</style>