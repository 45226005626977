import ApiService from "./api.service";

const PhotoService = {
    async deletePhoto(id) {
        return ApiService.delete('/api/photos/' + id)
    },

    async uploadPhoto(photo, image) {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('job_id', photo.job_id)
        formData.append('description', photo.description)
        formData.append('type', photo.type)
        formData.append('before_id', photo.before_id)
        return ApiService.customRequest({
            url: '/api/photos',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    async updatePhoto(photo) {
        return ApiService.post('/api/photos/' + photo.id, photo)
    },

    async downloadPhoto(id) {
        return ApiService.get('api/photos/' + id)
    },

    async downloadThumbnail(id) {
        return ApiService.get('api/photo-thumbnails/' + id)
    }
}

export default PhotoService