<template>
  <ion-list>
    <location-item
      v-for="(location, index) in locations"
      :key="index"
      :location="location"
      :itemClass="'row' + (index % 2)"
    ></location-item>
  </ion-list>
</template>

<script>
import { IonList } from "@ionic/vue";
import LocationItem from "./ListItem";
export default {
  name: "LocationList",
  components: { IonList, LocationItem },
  props: {
    locations: {
      type: Array,
    },
  },
};
</script>

<style>
</style>