<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center"
        ><strong>{{ translate("Extra work details") }}: </strong>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{
          translate("Close")
        }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <form class="ion-padding" @submit.prevent="addExtraHourDetails">
    <ion-list>
      <ion-item>
        <ion-label position="floating">{{
          translate("Extra work description")
        }}</ion-label>
        <ion-textarea v-model="extraHoursDescription"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-item>
        <ion-label position="floating">{{
          translate("Extra work time")
        }}</ion-label>

        <ion-datetime
          display-format="HH:mm"
          minute-values="0,15,30,45"
          value="01:00"
          min="00:15"
          v-model="extraHoursTime"
        ></ion-datetime>
      </ion-item>
    </ion-list>
    <ion-button @click="dismissModal(-1)">{{ translate("Cancel") }}</ion-button>
    <ion-button type="submit">{{ translate("Save") }}</ion-button>
  </form>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  modalController,
  toastController,
  IonTextarea,
  IonDatetime,
} from "@ionic/vue";
import { defineComponent } from "vue";
import InterventionService from "@/services/intervention.service";
import { mapActions } from "vuex";
import { getMinutes, getHours } from "date-fns";
export default defineComponent({
  name: "TaskModal",
  props: {
    interventionId: {
      type: Number,
    },
  },
  data() {
    return { extraHoursDescription: "", extraHoursTime: "" };
  },
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonButtons,
    IonTextarea,
    IonDatetime,
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async dismissModal() {
      await modalController.dismiss();
    },
    async addExtraHourDetails() {
      if (!this.extraHoursDescription || !this.extraHoursTime) {
        const toast = await toastController.create({
          message: this.translate("pleaseAddDescriptionAndTime"),
          position: "bottom",
          duration: 3000,
          color: "danger",
        });
        await toast.present();
        return;
      }
      await InterventionService.setExtraHoursDetails(
        this.interventionId,
        this.extraHoursDescription,
        this.timeToDecimal()
      )
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The details were added"),
            position: "botton",
            duration: 3000,
            color: "success",
          });
          await toast.present();
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
      this.fetchIntervention(this.interventionId).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
      await modalController.dismiss();
    },
    timeToDecimal() {
      const time = new Date(this.extraHoursTime);
      return getHours(time) + getMinutes(time) / 60;
    },
  },
});
</script>

<style>
</style>