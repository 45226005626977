<template>
  <ion-item>
    <ion-avatar slot="start">
      <ion-icon :icon="documentAttach" size="large" color="tertiary"></ion-icon>
    </ion-avatar>
    <ion-label>
      <h2>{{ document.name }}</h2>
      <p className="ion-text-wrap">{{ document.comment }}</p>
    </ion-label>
    <ion-icon
      :icon="downloadOutline"
      @click="downloadDocument()"
      size="large"
    ></ion-icon>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonIcon, IonAvatar } from "@ionic/vue";
import { documentAttach, downloadOutline } from "ionicons/icons";
import axios from "axios";

export default {
  name: "DocListItem",
  props: {
    document: Object,
  },
  components: {
    IonItem,
    IonLabel,
    IonIcon,
    IonAvatar,
  },
  data() {
    return {
      documentAttach,
      downloadOutline,
    };
  },
  methods: {
    async downloadDocument() {
      axios({
        url: "/api/documents/" + this.document.id,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // Service that handles ajax call
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.document.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
  },
};
</script>

<style>
</style>