<template>
  <base-layout :pageTitle="pageTitle" :pageDefaultBackLink="backDefaultUrl">
    <!-- <add-edit-task-form @save-task="saveTask"></add-edit-task-form> -->
    <add-edit-task-form
      :formType="formType"
      :plannedTask="plannedTask"
    ></add-edit-task-form>
  </base-layout>
</template>

<script>
import AddEditTaskForm from "@/components/tasks/AddEditForm";
import BaseLayout from "@/components/base/BaseLayout.vue";
export default {
  components: { BaseLayout, AddEditTaskForm },
  name: "AddEditTask",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
    plannedTask: {
      type: String,
      default: "false",
    },
  },
  computed: {
    pageTitle() {
      return this.formType + " " + this.translate("Task");
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({ name: "Interventions" });
      return href;
    },
  },
  methods: {
    //   saveTask(taskData) {
    //   }
  },
};
</script>

<style>
</style>