<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center"
        ><strong>{{ translate("Task") }}: </strong> {{ id }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{translate("Close")}}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item>
        <ion-label>
          <strong>{{ translate("Quantity") }}: </strong> {{ quantity }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <strong
            >{{ translate("Task") + " " + translate("Category") }} :
          </strong>
          {{ category }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <strong>{{ translate("Description") }}: </strong> {{ description }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <strong
            >{{ translate("Description") + " " + translate("Technician") }}:
          </strong>
          {{ technicianDescription }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TaskModal",
  props: {
    id: {
      type: Number,
    },
    quantity: {
      type: Number,
    },
    category: {
      type: String,
    },
    description: {
      type: String,
    },
    technicianDescription: {
      type: String,
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonButtons,
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
  },
  created() {
    // console.log(this.technicianDescription)
  }
});
</script>

<style>
</style>