<template>
  <ion-list>
    <ion-item-group
      v-for="(beforePhoto, indexBefore) in orderedPhotos"
      :key="indexBefore"
    >
      <ion-item-divider color="warning"> </ion-item-divider>
      <photo-list-item
        :photo="beforePhoto"
        itemClass="row0"
        :before="true"
      ></photo-list-item>
      <photo-list-item
        v-for="(afterPhoto, index) in beforePhoto.afterPhotos"
        :key="index"
        :photo="afterPhoto"
        itemClass="row1"
      ></photo-list-item>
    </ion-item-group>
  </ion-list>
</template>

<script>
import { IonList, IonItemGroup, IonItemDivider } from "@ionic/vue";
import PhotoListItem from "./ListItem";
export default {
  name: "PhotoList",
  components: {
    IonList,
    PhotoListItem,
    IonItemGroup,
    IonItemDivider,
  },
  computed: {
    orderedPhotos() {
      const beforePhotos = this.photos.filter(
        (photo) => photo.type === "before"
      );
      const afterPhotos = this.photos.filter((photo) => photo.type === "after");
      beforePhotos.forEach(
        (beforePhoto) =>
          (beforePhoto.afterPhotos = afterPhotos.filter(
            (afterPhoto) => afterPhoto.before_id === beforePhoto.id
          ))
      );
      return beforePhotos;
    },
  },
  props: ["photos"],
};
</script>

<style>
</style>