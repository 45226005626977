<template>
  <ion-list>
    <ion-item-group>
      <ion-item-divider color="warning" v-if="allowedIntervDocs.length != 0">
        <ion-label>
          <strong
            >{{ translate("Intervention") + " " + translate("Docs") }}
          </strong>
        </ion-label>
      </ion-item-divider>
      <doc-list-item
        v-for="(document, index) in allowedIntervDocs"
        :key="index"
        :document="document"
      >
      </doc-list-item>
      <ion-item-divider color="danger" v-if="allowedProjectDocs.length != 0">
        <ion-label>
          <strong>{{ translate("Project") + " " + translate("Docs") }}</strong>
        </ion-label>
      </ion-item-divider>
      <doc-list-item
        v-for="(projDoc, projIndex) in allowedProjectDocs"
        :key="projIndex"
        :document="projDoc"
      ></doc-list-item>
    </ion-item-group>
  </ion-list>
</template>

<script>
import { IonList, IonItemGroup, IonItemDivider, IonLabel } from "@ionic/vue";
import DocListItem from "./ListItem.vue";
import UserService from "@/services/user.service";
export default {
  name: "DocList",
  components: {
    IonList,
    DocListItem,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
  },
  props: {
    docs: Array,
    projectDocs: Array,
  },
  data() {
    return {
      userDetails: {},
    };
  },
  computed: {
    allowedIntervDocs() {
      return this.docs.filter(
        (doc) =>
          doc.access_permission !== "administrator" ||
          this.userDetails?.role?.toLowerCase().includes("direction") ||
          this.userDetails?.role?.toLowerCase().includes("administrator") ||
          this.userDetails?.role?.toLowerCase().includes("administratif")
      );
    },
    allowedProjectDocs() {
      return this.projectDocs.filter(
        (doc) =>
          doc.access_permission !== "administrator" ||
          this.userDetails?.role?.toLowerCase().includes("direction") ||
          this.userDetails?.role?.toLowerCase().includes("administrator") ||
          this.userDetails?.role?.toLowerCase().includes("administratif")
      );
    },
  },
  async created() {
    UserService.getInfo(1)
      .then((response) => {
        this.userDetails = response.data;
      })
      .catch((error) =>
        this.$router.push({ name: "ErrorDisplay", params: { error } })
      );
  },
};
</script>

<style>
</style>