<template>
  <ion-content class="ion-padding"
    >{{ translate("Photos") + " " + translate("list") }}
    <photo-list :photos="photos"></photo-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddPhotoClick">
        <ion-icon :icon="camera"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</template>

<script>
import { IonContent, IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import PhotoList from "@/components/photos/List";
import { camera } from "ionicons/icons";
export default {
  props: ["photos", "interventionId"],
  inject: ["GStore"],
  components: { IonContent, PhotoList, IonFab, IonFabButton, IonIcon },
  data() {
    return { camera };
  },
  methods: {
    handleAddPhotoClick() {
      this.GStore.photo = { job_id: this.interventionId };
      this.$router.push({
        name: "PhotoCreateEdit",
        params: { formType: "Create" },
      });
    },
  },
};
</script>

<style>
</style>