export default
    {
        "Project": "Projet",
        "Projects": "Projets",
        "Intervention": "Intervention",
        "Interventions": "Interventions",
        "Create": "Créer",
        "Update": "Modifier",
        "process": "Procéder",
        'list': 'Liste',
        "Details": "Détails",
        "Show more": "Voir plus",
        "Unfinished Interventions": "Interventions en cours",
        "Profile": "Profil",
        "Location": "Location",
        "Hours": "Heures",
        "Tasks": "Tâches",
        "Photos": "Photos",
        "Products": "Matériaux",
        "Docs": "Docs",
        "Tags": "Tags",
        "Task": "Tâches",
        "Photo": "Photo",
        "Product": "Matériel",
        "Doc": "Doc",
        "Tag": "Tag",
        "Planned": "Planifié",
        "UnPlanned": "Non-planifié",
        "Completed": "Complétée",
        "Filter": "Filtrer",
        "Estimated": "Estimé",
        "Used": "Utilisé",
        "Locked": "Bloqué",
        "Usable": "Utilisable",
        "Released": "Libre",
        "Today": "Aujourd'hui",
        "Tomorrow": "Demain",
        "Monday": "Lundi",
        "Planning": "Planning",
        "Home": "Accueil",
        "Open": "Ouvert",
        "Close": "Fermé",
        "Title": "Titre",
        "Start": "Start",
        "End": "End",
        "Save": "Enregistrer",
        "Cancel": "Annulé",
        "Description": "Description",
        "Delete": "Supprimé",
        "After": "Après",
        "Before": "Avant",
        "Quantity": "Quantité",
        "Comment": "Commentaire",
        "Please Sign In!": "Merci de se connecter!",
        "Sign In": "Se Connecter",
        "Username": "Utilisateur",
        "Password": "Mot de passe",
        "Failed": "Echec",
        "Sign in Failed": "Echec de connexion",
        "PLANNING": "PLANNING",
        "INTERVENTIONS": "INTERVENTIONS",
        "PROJECTS": "PROJETS",
        "PROFILE": "PROFIL",
        "Logging out": "Se Déconnecter",
        "Logout of the application?": "Se déconnecter de l'application ?",
        "Settings": "Paramètres",
        "Birthdate": "Date de naissance",
        "Vehicle": "Véhicule",
        "AVS": "AVS",
        "IBAN": "IBAN",
        "Bank": "Banque",
        "System Configuration": "Configuration du système",
        "Importance": "Importance",
        "Normal": "Normal",
        "Critical": "Critique",
        "type": "type",
        "Standard": "Standard",
        "Category": "Categorie",
        "Process": "Procédés",
        "tab": "Onglet",
        "Refreshing": "Raffraichir",
        "Show more detail": "Voir plus de détails",
        "The given data was invalid.": "Les informations données ne sont pas valides.",
        "Cannot update intervention": "Impossible de modifier l'intervention",
        "Cannot update product transaction": "Impossible de modifier l'ajout du matériel",
        "Cannot edit lock more than Available quantity": "Impossible d'ajouter plus que la quantité disponible",
        "Cannot edit lock less than already used material quantity": "Impossible d'ajouter moins que la quantité déjà utilisée",
        "Lock is already released cannot edit material": "La quantité bloqué est déjà libérée, impossible d'éditer le matériel",
        "Cannot use product more than Available quantity": "Impossible d'utiliser plus que la quantité disponible de ce matériel",
        "Cannot delete used product": "Impossible de supprimer un matériel déjà utilisé",
        "Lock is already released for this product": "Le blocage est déjà libéré pour ce matériel",
        "Cannot delete used product transaction: not of type out": "Impossible de supprimer des matériaux déjà utilisés: not of type out",
        "Lock quantity for this product is already released cannot add as used": "La quantité bloquée de ce matériel est déjà libérée, impossible de l'ajouter en utilisé",
        "Cannot find estimated product of type lock": "Impossible de trouver le matériel estimé",
        "Cannot release lock employee is not assigned to any intervention for this project": "Impossible de libérer l'employé bloqué qui n'est pas assigné à aucune intervention sur ce projet",
        "Cannot release lock of 0 quantity": "Impossible de libérer une quantité de 0",
        "Cannot create task": "Impossible de créer cette tâche",
        "Cannot delete Task": "Impossible de supprimer cette tâche",
        "Cannot add task as completed": "Impossible de compléter cette tâche",
        "Name": "Nom",
        "System Language": "Langue système",
        "Phone": "Nº de téléphone",
        "Address": "Adresse",
        "Email Address": "Adresse email",
        "Postal Code": "Code Postal",
        "City": "Ville",
        "Departure": "Départ",
        "On site": "Sur site",
        "Finished": "Fin",
        "Arrival": "Arrivée",
        "Other": "Autre",
        "Edit and Add": "Modifier",
        "Are you sure to delete this Task?": "Etes-vous sur de supprimer cette tâche ?",
        "Use": "Utilisé",
        "Edit Lock": "Modifier le matériel bloqué",
        "Edit and use": "Modifier et utiliser le matériel",
        "Times the task was performed": "Nombre de fois que la tâche a été effectuée",
        "Select": "Selectionner",
        "CONFIRM INTERVENTION": "CONFIRMER L'INTERVENTION",
        "GARAGE DEPARTURE": "DEPART VERS CHANTIER",
        "ON SITE": "ARRIVEE SUR CHANTIER",
        "FINISHED": "INTERVENTION TERMINEE",
        "GARAGE ARRIVAL": "ARRIVEE DEPOT OU NOUVEAU DEPART",
        "Is this project finished?": "Est-ce que ce projet est terminé ?",
        "Are you sure to delete this Product?": "Etes-vous sur de supprimer ce matériel ?",
        "The photo was uploaded": "La photo a été enregistrée",
        "created": "Crée",
        "updated": "Modifié",
        "deleted": "Supprimé",
        "The product was added as used": "Le matériel a été ajouté et utilisé",
        "The product was deleted": "Le matériel a été supprimé",
        "The Estimated lock is released": "Le matériel estimé a été débloqué",
        "The user was updated": "L'utilisateur a été mis à jour",
        "The task was added as completed": "La tâche a été ajoutée et complétée",
        "The project was updated to finished": "Le projet a été mis à jour et terminé",
        "Enter description of task": "Entrer la description de la tâche",
        "Intervention confirmed!": "L'intervention est confirmée",
        "Location sent!": "La localisation a été mis à jour",
        "Employee": "Employé",
        "Timezone": "Fuseau horaire",
        "Status": "Statut",
        "Please wait": "Veuillez patienter",
        "Pull to refresh": "Tirer pour rafraîchir",
        "Week": "Semaine",
        "Month": "Mois",
        "Refresh": "Raffraichir",
        "2 Day": "2 Journée",
        "History": "Historique",
        "Technician": "Technicien",
        "Planned task?": "Tâche planifiée ?",
        "Floor": "Etage",
        "The project was updated to in process": "Le projet a été mis à jour en tant que en cours",
        "Contact": "Contact sur site",
        "Contact phone": "Tel. contact site",
        "private": "Privé",
        "common_place": "Place Commune",
        "tenant": "Locataire",
        "Tenant": "Locataire",
        "Tenant Phone": "Tel. locataire",
        "Projects in process": "Projets en cours",
        "Order number": "Nº d'ordre",
        "Quotation number": "Nº de devis",
        "The start date and end date must be the same": "La date de début et la date de fin doivent être les mêmes",
        "The end date must be after the start date": "La date de fin doit être supérieure à la date de début",
        "You cannot create an intervention in the past": "Vous ne pouvez pas créer une intervention dans le passé",
        "Admin": "Admin",
        "Owner": "Propriétaire",
        "You can only create an intervention starting 2 hours in the past max": "Vous ne pouvez créer une intervention plus de 2h après son début.\nPar contre vous pouvez la créer dans le présent et changer l’heure.",
        "No": "Non",
        "Yes": "Oui",
        "Extra work hours": "Travail supplémentaire",
        "Did you have extra work?": "Avez-vous eu du travail supplémentaire ?",
        "Extra work details": "Détails du travail supplémentaire",
        "Extra work description": "Description du travail supplémentaire",
        "Extra work time": "Temp du travail supplémentaire",
        "The details were added": "Les TS ont été ajoutés",
        "Go to details": "Aller aux détails",
        "Project planned tasks": "Tâches planifiées du projet",
        "Intervention planned tasks": "Tâches planifiées de l'intervention",
        "Completed tasks": "Tâches complétées",
        "Finished Projects": "Projets à facturer",
        "Project Details": "Détails du projet",
        "Type in search to see project": "Tapez dans la recherche pour voir le projet",
        "Search": "Recherche",
        "Technician Intervention Description": "Description Intervention",
        "Add": "Ajouter",
        "noTsForThisIntervention": "Vous n'avez pas ajouté de TS pour cette intervention",
        "pleaseAddDescriptionAndTime": "Veuillez ajouter une description et un temps",
        "typeOfMaterial": "Type de matériel",
        "pleaseSelectAProduct": "Veuillez sélectionner un matériel",
        "tsMaterial": "Matériel Travaux Suppl.",
        "normalMaterial": "Matériel intervention dépannage",
        "pleaseSelectTypeOfMaterial": "Veuillez sélectionner le type de matériel",
        "theProductWasAdded": "Le matériel a été ajouté",
        "theProductWasUpdated": "Le matériel a été mis à jour",
    }