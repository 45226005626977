<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center"
        ><strong>Photo: </strong> {{ id }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">{{
          translate("Close")
        }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-img :src="photoUrl"></ion-img>
    <p>{{ description }}</p>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  modalController,
  IonImg,
} from "@ionic/vue";
import { defineComponent } from "vue";
import PhotoService from "@/services/photo.service";
export default defineComponent({
  name: "PhotoModal",
  props: {
    id: {
      type: Number,
    },
    description: {
      type: String,
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonImg,
  },
  data() {
    return {
      photoUrl: "",
    };
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
  },
  async created() {
    const photoBinaries = await PhotoService.downloadPhoto(this.id)
      .then((response) => response.data)
      .catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
    this.photoUrl = photoBinaries;
  },
});
</script>

<style scoped>
p {
  margin: 10px;
}
</style>