import ApiService from "./api.service";

const TaskService = {
    async deleteTask(id) {
        return ApiService.delete('/api/tasks/' + id)
    },

    async getTaskCategories() {
        return ApiService.get('/api/task/task-categories')
    },

    async createTask(task) {
        return ApiService.post('/api/tasks', task)
    },

    async updateTask(task) {
        return ApiService.post('api/tasks/' + task.id, task)
    },

    async addAsCompletedTask(taskId, interventonId) {
        return ApiService.post('/api/task/add-as-completed', { taskId, interventonId })
    },

    async getTaskHistory(projectId) {
        return ApiService.get('api/task/get-task-history/' + projectId)
    },

    async addTechnicianDescription(taskId, interventonId, technicianDescription, flag) {
        return ApiService.post('/api/task/add-technician-description', { taskId, interventonId, technicianDescription, flag })
    },
}

export default TaskService