<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="redirect">
            <ion-icon slot="icon-only" :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
    </ion-content>
    <ion-footer v-if="showFooter">
      <slot name="footerContent"></slot>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
} from "@ionic/vue";

import { arrowBack } from "ionicons/icons";

export default {
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    pageDefaultBackLink: {
      type: String,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonButtons,
    IonFooter,
    IonIcon,
  },
  data() {
    return {
      arrowBack,
    };
  },
  methods: {
    redirect() {
      this.$router.push(this.pageDefaultBackLink);
    },
  },
};
</script>