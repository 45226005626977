<template>
  <ion-item-sliding>
    <!--:disabled="disableSliding" used to disable sliding-->
    <ion-item-options side="start" v-if="projectProduct">
      <ion-item-option
        color="danger"
        @click="handleRelease"
        :disabled="product.release > 0"
        >Release: {{ available }}</ion-item-option
      >
    </ion-item-options>
    <ion-item-options>
      <ion-item-option
        :color="projectProduct ? 'success' : 'danger'"
        @click="projectProduct ? addAsUsedProduct() : presentDeleteAlert()"
        :disabled="projectProduct ? product.release > 0 : false"
      >
        {{
          projectProduct
            ? translate("Use") + " " + available
            : translate("Delete")
        }}
      </ion-item-option>
      <ion-item-option
        color="danger"
        @click="handleLock"
        :disabled="projectProduct ? product.release > 0 : false"
      >
        {{ projectProduct ? translate("Edit Lock") : "" }}
      </ion-item-option>
      <ion-item-option
        color="warning"
        @click="handleEdit"
        :disabled="projectProduct ? product.release > 0 : false"
      >
        {{ projectProduct ? translate("Edit and use") : translate("Update") }}
      </ion-item-option>
    </ion-item-options>
    <ion-item class="" @click="openModal()" :style="{ color: quantityColor }">
      <ion-label class="ion-text-start">
        {{ product.qty }}
      </ion-label>
      <ion-label class="ion-text-wrap ion-text-start">
        {{ product.product.name }}
      </ion-label>
      <ion-label class="ion-text-wrap ion-text-center" v-if="projectProduct">
        {{ product.available }}
      </ion-label>
      <ion-label class="ion-text-wrap ion-text-center" v-if="projectProduct">
        {{ product.release }}
      </ion-label>
      <ion-icon
        v-if="projectProduct"
        slot="end"
        :icon="available == 0 ? checkmarkCircleOutline : closeCircleOutline"
        :color="available == 0 ? 'success' : 'danger'"
      >
      </ion-icon>
    </ion-item>
  </ion-item-sliding>
</template>

<script>
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import {
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  alertController,
  toastController,
  modalController,
  IonIcon,
} from "@ionic/vue";
import ProductService from "@/services/product.service";
import { mapActions, mapGetters } from "vuex";
import ProductModal from "./Modal";
export default {
  props: {
    product: {
      type: Object,
    },
    itemClass: {
      type: String,
      default: "row0",
    },
    projectProduct: {
      type: Boolean,
    },
    disableSliding: {
      type: Boolean,
      default: false,
    },
    quantityColor: {
      type: String,
      default: "black",
    },
    available: {
      type: Number,
      default: 0,
    },
  },
  inject: ["GStore"],
  components: {
    IonItemSliding,
    IonItem,
    IonItemOptions,
    IonItemOption,
    IonLabel,
    IonIcon,
  },
  data() {
    return {
      checkmarkCircleOutline,
      closeCircleOutline,
    };
  },
  computed: {
    ...mapGetters("intervention", ["currentInterventionData"]),
  },
  methods: {
    ...mapActions("intervention", ["fetchIntervention"]),
    async addAsUsedProduct() {
      await ProductService.addAsUsedProduct(
        this.product.id,
        this.currentInterventionData.id,
        this.product.product_id,
        this.product.project_id
      )
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The product was added as used"),
            position: "botton",
            duration: "3000",
            color: "success",
          });
          await toast.present();
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
      this.fetchIntervention(this.currentInterventionData.id).catch((error) => {
        this.$router.push({ name: "ErrorDisplay", params: { error } });
      });
    },
    async presentDeleteAlert() {
      const alert = await alertController.create({
        header: this.translate("Delete") + " " + this.translate("Product"),
        message:
          "<strong>" +
          this.translate("Are you sure to delete this Product?") +
          "</strong>",
        buttons: [
          {
            text: this.translate("Cancel"),
            role: "cancel",
            cssClass: "primary",
          },
          {
            text: this.translate("Delete"),
            role: "ok",
            cssClass: "danger",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === "ok") {
        await ProductService.deleteProductTransaction(this.product.id)
          .then(async () => {
            const toast = await toastController.create({
              message: this.translate("The product was deleted"),
              position: "bottom",
              duration: 4000,
              color: "success",
            });
            await toast.present();
            this.fetchIntervention(this.currentInterventionData.id).catch(
              (error) => {
                this.$router.push({ name: "ErrorDisplay", params: { error } });
              }
            );
          })
          .catch(async (error) => {
            console.log("893", error);
            const toast = await toastController.create({
              message: this.translate(error.response.data),
              position: "bottom",
              duration: 4000,
              color: "danger",
            });
            await toast.present();
          });
      }
    },
    handleEdit() {
      this.GStore.product = { ...this.product };
      if (this.projectProduct) {
        this.GStore.product.job_id = this.currentInterventionData.id;
        this.GStore.product.qty = 0;
      }
      this.$router.push({
        name: "ProductCreateEdit",
        params: {
          formType: "Update",
          projectProduct: this.projectProduct,
          componentKey: "edit",
        },
      });
    },
    handleLock() {
      this.GStore.product = { ...this.product };
      if (this.projectProduct) {
        this.GStore.product.job_id = this.currentInterventionData.id;
      }
      this.$router.push({
        name: "ProductCreateEdit",
        params: {
          formType: "Update",
          projectProduct: false,
          componentKey: "lock",
        },
      });
    },
    async handleRelease() {
      await ProductService.releaseLock(
        this.product.product_id,
        this.product.project_id
      )
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The Estimated lock is released"),
            position: "botton",
            duration: "3000",
            color: "success",
          });
          await toast.present();
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data),
            position: "bottom",
            duration: 4000,
            color: "danger",
          });
          await toast.present();
        });
    },
    async openModal() {
      const modal = await modalController.create({
        component: ProductModal,
        componentProps: {
          id: this.product.id,
          quantity: this.product.qty,
          name: this.product.product.name,
          description: this.product.comment,
          ts: this.product.ts,
        },
      });
      await modal.present();
    },
  },
  created() {},
};
</script>

<style>
.row1 {
  --background: var(--ion-color-light);
}

.row0 {
  --background: #ffffff;
}
</style>