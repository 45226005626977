<template>
  <ion-item-sliding>
    <ion-item class="" :color="tagIndex % 2 == 0 ? 'light' : ''">
      <ion-label class="ion-text-start">
        {{ tag.title }}
      </ion-label>
      <ion-label class="ion-text-wrap ion-text-start">
        {{ tag.importance }}
      </ion-label>
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option color="secondary" @click="handleEdit">
        {{ translate("Update") }}
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</template>

<script>
import {
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
} from "@ionic/vue";
import { mapState } from "vuex";

export default {
  name: "TagListItem",
  props: {
    tag: Object,
    tagIndex: Number,
  },
  inject: ["GStore"],
  components: {
    IonItem,
    IonLabel,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
  },
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
  },
  methods: {
    handleEdit() {
      this.GStore.tag = { ...this.tag };
      this.GStore.tag.job_id = this.currentIntervention.id;
      this.$router.push({
        name: "TagCreateEdit",
        params: {
          formType: "Update",
        },
      });
    },
  },
};
</script>

<style>
</style>