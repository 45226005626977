<template>
  <ion-list>
    <ion-item-divider :sticky="true" color="tertiary">
      <ion-label>
        <strong>{{ translate("Project planned tasks") }}</strong>
      </ion-label>
    </ion-item-divider>
    <task-list-item
      :plannedTask="true"
      :projectTask="true"
      v-for="(projectTask, index) in projectTasks"
      :key="index"
      :task="projectTask"
    >
      <!--
      :itemClass="'row' + (index % 2)"
      :disableSliding="
        tasks.some((value) => value.task_category_id === task.task_category_id)
      "-->
    </task-list-item>
    <ion-item-divider :sticky="true" color="warning">
      <ion-label
        ><strong>
          {{ translate("Intervention planned tasks") }}
        </strong>
      </ion-label>
    </ion-item-divider>
    <task-list-item
      :plannedTask="true"
      :projectTask="false"
      v-for="(task, index) in tasks.filter((task) => task.status == 'planned')"
      :key="index"
      :task="task"
      :quantityColor="'black'"
    >
    </task-list-item>
    <ion-item-divider :sticky="true" color="success">
      <ion-label
        ><strong>
          {{ translate("Completed tasks") }}
        </strong>
      </ion-label>
    </ion-item-divider>
    <task-list-item
      :plannedTask="false"
      :projectTask="false"
      v-for="(task, index) in tasks.filter((task) => task.status == 'completed')"
      :key="index"
      :task="task"
      :quantityColor="'black'"
    >
    </task-list-item>
  </ion-list>
</template>

<script>
import { IonList, IonItemDivider, IonLabel } from "@ionic/vue";
import TaskListItem from "./ListItem";
export default {
  name: "TaskList",
  components: { IonList, TaskListItem, IonItemDivider, IonLabel },
  props: ["tasks", "projectTasks"],
  methods: {
    quantityColor(task) {
      let color = "black";
      this.projectTasks.forEach(
        (projectTask) => {
          if (projectTask.task_category_id === task.task_category_id) {
            if (projectTask.quantity > task.quantity) {
              color = "#ff3300"; //red
            } else if (projectTask.quantity < task.quantity) {
              color = "#0066ff"; //blue
            } else {
              color = "#808080"; //grey
            }
          }
        },
        color,
        task
      );
      return color;
    },
  },
};
</script>

<style></style>
