<template>
  <div class="map" ref="mapDivRef"></div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
export default {
  name: "GMap",
  props: {
    zoom: Number,
    disableUI: Boolean,
    maptype: String,
    marker: Array,
    title: String
  },
  setup(props) {
    const map = ref(null);
    const mapDivRef = ref(null);

    onBeforeMount(() => {
      // console.log(props.marker)
      // const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
      const googleMapScript = document.createElement("SCRIPT");
      googleMapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyB1PZGGH5kogSeV4dwo7fckxeWSp6yRdmw&callback=initMap`
      );
      googleMapScript.setAttribute("async", "");
      googleMapScript.setAttribute("defer", "");
      document.head.appendChild(googleMapScript);
    });

    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: props.maptype || "roadmap",
        zoom: props.zoom || 12,
        disableDefaultUI: props.disableUI || false,
        center: {lat: props.marker[1], lng: props.marker[0]} || { lat: 46.208, lng: 6.149059 },
      });
      new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          props.marker[1],
          props.marker[0]
        ),
        map: map.value,
        title: props.title
      });
    };

    return {
      mapDivRef,
    };
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 300px;
}
</style>