<template>
  <ion-content>
    <!-- List of Text Items -->
    <ion-list>
      <ion-item @click="openModal()">
        <ion-label>{{ translate("Show more detail") }} </ion-label>
      </ion-item>
      <!-- <ion-item>
        <ion-label color="medium"
          >{{ translate("Update") + " " + translate("Intervention") }}
        </ion-label>
      </ion-item> -->
      <ion-item @click="handleTag">
        <ion-label>{{ translate("Tags") }}</ion-label>
      </ion-item>
      <ion-item @click="showTaskHistory">
        <ion-label>{{
          translate("Tasks") + " " + translate("History")
        }}</ion-label>
      </ion-item>
      <ion-item @click="addTs()" :disabled="hasTS || !interventionIsFinished">{{
        translate("Add") + " TS"
      }}</ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonLabel,
  IonItem,
  IonList,
  modalController,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import SecondaryInfoModal from "./SecondaryInfoModal";
import { mapGetters } from "vuex";
import InterventionService from "../../../services/intervention.service.js";
import ExtraHourModal from "@/components/interventions/ExtraHourModal.vue";

export default defineComponent({
  name: "Popover",
  props: {
    interventionId: {
      type: Number,
    },
  },
  data() {
    return {
      interventionData: {},
    };
  },
  components: {
    IonContent,
    IonLabel,
    IonItem,
    IonList,
  },
  inject: ["GStore"],
  computed: {
    ...mapGetters("intervention", ["currentInterventionData"]),
    hasTS() {
      return (
        (this.currentInterventionData.extra_hours_description !== null &&
          this.currentInterventionData.extra_hours_description !== "") ||
        (this.currentInterventionData.extra_hours_time !== null &&
          this.currentInterventionData.extra_hours_time !== "0.00")
      );
    },
    interventionIsFinished() {
      return this.currentInterventionData.arrival !== null;
    },
  },
  methods: {
    async openModal() {
      await popoverController.dismiss();
      const modal = await modalController.create({
        component: SecondaryInfoModal,
        componentProps: {
          redirectedFrom: "intervention-details",
        },
      });
      await modal.present();
    },
    async handleEdit() {
      await InterventionService.getInterventionDetails(this.interventionId)
        .then((response) => {
          this.interventionData = response.data;
        })
        .catch((error) => {
          throw error;
        });

      await popoverController.dismiss();
      this.GStore.intervention = this.interventionData;
      this.$router.push({
        name: "InterventionCreateEdit",
        params: {
          formType: "Update",
        },
      });
    },
    async handleTag() {
      await popoverController.dismiss();
      this.$router.push({
        name: "TagTabRoute",
        params: { key: Math.floor(Math.random() * (10000 - 1 + 1) + 1) },
      });
    },
    async showTaskHistory() {
      await popoverController.dismiss();
      this.$router.push({
        name: "TaskHistoryTabRoute",
        params: {
          key: Math.floor(Math.random() * (10000 - 1 + 1) + 1),
          projectId: this.currentInterventionData.project_id,
        },
      });
    },
    async addTs() {
      await popoverController.dismiss();
      const modal = await modalController.create({
        component: ExtraHourModal,
        componentProps: {
          interventionId: this.interventionId,
        },
      });
      await modal.present();
    },
  },
});
</script>
