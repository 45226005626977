<template>
  <base-layout :pageTitle="pageTitle" :pageDefaultBackLink="backDefaultUrl">
    <!-- <add-edit-task-form @save-task="saveTask"></add-edit-task-form> -->
    <add-edit-product-form
      :formType="formType"
      :projectProduct="projectProduct"
      :key="componentKey"
    ></add-edit-product-form>
  </base-layout>
</template>

<script>
import AddEditProductForm from "@/components/products/AddEditForm";
import BaseLayout from "@/components/base/BaseLayout.vue";
export default {
  components: { BaseLayout, AddEditProductForm },
  name: "AddEditProduct",
  props: {
    formType: {
      type: String,
      default: "Create",
    },
    projectProduct: {
      type: String,
      default: "false",
    },
    componentKey: {
      type: String,
    },
  },
  computed: {
    pageTitle() {
      return this.translate(this.formType) + " " + this.translate("Product");
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({ name: "Interventions" });
      return href;
    },
  },
};
</script>

<style>
</style>