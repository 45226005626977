<template>
  <base-layout
    :pageTitle="
      translate('Intervention') +
      ' ' +
      translate('Details') +
      ': ' +
      currentIntervention.title
    "
    :pageDefaultBackLink="backDefaultUrl"
  >
    <ion-refresher slot="fixed" @ionRefresh="refreshComponent($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        :pulling-text="translate('Pull to refresh')"
        refreshing-spinner="circles"
        :refreshing-text="translate('Refreshing') + '...'"
      >
      </ion-refresher-content>
    </ion-refresher>
    <ion-content class="ion-padding">
      <tag-list :tags="tags"></tag-list>
    </ion-content>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleAddTagClick">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout>
</template>

<script>
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { chevronDownCircleOutline } from "ionicons/icons";
import TagList from "@/components/tags/List.vue";
import BaseLayout from "@/components/base/BaseLayout.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { add } from "ionicons/icons";

export default {
  inject: ["GStore"],
  components: {
    TagList,
    IonContent,
    BaseLayout,
    IonFab,
    IonFabButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      add,
      chevronDownCircleOutline,
    };
  },
  computed: {
    ...mapState("intervention", ["currentIntervention"]),
    ...mapGetters("tag", ["tags"]),
    pageTitle() {
      return "Tags";
    },
    backDefaultUrl() {
      const { href } = this.$router.resolve({
        name: "InterventionTabsRoute",
        params: { id: this.currentIntervention.id },
      });
      return href;
    },
  },
  methods: {
    ...mapActions("tag", ["fetchInterventionTags"]),
    handleAddTagClick() {
      this.GStore.tag = {
        job_id: this.currentIntervention.id,
      };
      this.$router.push({
        name: "TagCreateEdit",
        params: {
          formType: "Create",
        },
      });
    },
    async refreshComponent(event) {
      await this.fetchInterventionTags(this.currentIntervention.id).catch(
        (error) => {
          this.$router.push({ name: "ErrorDisplay", params: { error } });
        }
      );
      event.target.complete();
    },
  },
  created() {
    this.fetchInterventionTags(this.currentIntervention.id).catch((error) => {
      this.$router.push({ name: "ErrorDisplay", params: { error } });
    });
  },
};
</script>

<style>
</style>