<template>
  <ion-list>
    <intervention-list-item
      v-for="intervention in interventions"
      :key="intervention.id"
      :intervention="intervention"
    >
      ></intervention-list-item
    >
  </ion-list>
</template>

<script>
import InterventionListItem from "./InterventionListItem";
import { IonList } from "@ionic/vue";
export default {
  props: ["interventions"],
  components: {
    InterventionListItem,
    IonList,
  },
};
</script>