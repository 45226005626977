import ProjectService from '../services/project.service'

const state = {
    unfinishedProjects: [],
    unfinishedProjectTasks: [],
    assignedToProjects: [],
    currentProject: {}
}

const getters = {
    unfinishedProjects: (state) => {
        return [...state.unfinishedProjects];
    },
    unfinishedProjectTasks: (state) => {
        return [...state.unfinishedProjectTasks];
    },
    unfinishedTasksQty: (state) => {
        return state.unfinishedProjectTasks.length;
    },
    assignedToProjects: (state) => {
        return [...state.assignedToProjects];
    },
    currentProject: (state) => {
        return { ...state.currentProject }
    },
    inProcessProjects: (state) => {
        return state.assignedToProjects.filter(
            (project) => project.status === "in_process"
        );
    },
    finishedProjects: (state) => {
        return state.assignedToProjects.filter(
            (project) => project.status === "finished"
        );
    },
}

const mutations = {
    SET_PROJECTS(state, projects) {
        state.unfinishedProjects = projects
    },
    SET_UNFINISHED_TASKS(state, unfinishedTasks) {
        state.unfinishedProjectTasks = unfinishedTasks
    },
    SET_ASSIGNED_TO_PROJECTS(state, assignedToProjects) {
        state.assignedToProjects = assignedToProjects
    },
    SET_CURRENT_PROJECT(state, project) {
        state.currentProject = project
    },
}

const actions = {
    fetchUnfinishedProjects({ commit }) {
        return ProjectService.getUnfinishedProjects()
            .then(response => {
                commit('SET_PROJECTS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
    fetchUnfinishedProjectTasks({ commit }, projectId) {
        return ProjectService.getUnfinsihedProjectTasks(projectId)
            .then(response => {
                commit('SET_UNFINISHED_TASKS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
    fetchAssignedToProjects({ commit }) {
        return ProjectService.getAssignedToProjects()
            .then(response => {
                commit('SET_ASSIGNED_TO_PROJECTS', response.data)
            })
            .catch(error => {
                throw (error)
            })
    },
    fetchCurrentProject({ commit }, projectId) {
        return ProjectService.getProjectDetails(projectId)
            .then(response => {
                commit('SET_CURRENT_PROJECT', response.data)
            })
            .catch(error => {
                throw (error)
            })
    }

}

export const project = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}