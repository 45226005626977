<template>
  <ion-list>
    <project-list-item
      v-for="project in projects"
      :key="project.id"
      :project="project"
    ></project-list-item>
  </ion-list>
</template>

<script>
import ProjectListItem from "./ProjectListItem.vue";
import { IonList } from "@ionic/vue";
export default {
  name: "ProjectList",
  props: {
    projects: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    ProjectListItem,
    IonList,
  },
};
</script>

<style>
</style>