<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label>{{ translate("Name") }}</ion-label>
        <ion-input type="text" v-model="GStore.profile.name"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Email Address") }}</ion-label>
        <ion-input type="email" v-model="GStore.profile.email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("System Language") }}</ion-label>
        <ion-select v-model="GStore.profile.language">
          <ion-select-option value="en">English</ion-select-option>
          <ion-select-option value="fr">French</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Timezone") }}</ion-label>
        <ion-select v-model="GStore.profile.timezone">
          <ion-select-option value="Europe/Zurich"
            >Europe/Zurich</ion-select-option
          >
          <ion-select-option value="Asia/Kolkata"
            >Asia/Kolkata</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Phone") }}</ion-label>
        <ion-input type="tel" v-model="GStore.profile.phone"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Address") }}</ion-label>
        <ion-input v-model="GStore.profile.address"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Postal Code") }}</ion-label>
        <ion-input v-model="GStore.profile.postal_code"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("City") }}</ion-label>
        <ion-input v-model="GStore.profile.city"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Birthdate") }}</ion-label>
        <ion-datetime
          display-format="DD.MM.YYYY"
          v-model="GStore.profile.birthdate"
        ></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("AVS") }}</ion-label>
        <ion-input v-model="GStore.profile.avs"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("IBAN") }}</ion-label>
        <ion-input v-model="GStore.profile.iban"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate("Bank") }}</ion-label>
        <ion-input v-model="GStore.profile.bank_name"></ion-input>
      </ion-item>
    </ion-list>
    <ion-button @click="$router.go(-1)">Cancel</ion-button>
    <ion-button type="submit">Save</ion-button>
  </form>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  toastController,
} from "@ionic/vue";
import UserService from "@/services/user.service";
export default {
  name: "EditProfileForm",
  inject: ["GStore", "LngGStore"],
  components: {
    IonLabel,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonDatetime,
  },
  methods: {
    async submitForm() {
      await UserService.updateUser(this.GStore.profile)
        .then(async () => {
          const toast = await toastController.create({
            message: this.translate("The user was updated"),
            position: "botton",
            duration: 3000,
            color: "success",
          });
          await toast.present();
          this.LngGStore.setLanguage = this.GStore.profile.language;
          this.$router.replace({
            name: "Profile",
            params: { key: Math.floor(Math.random() * 10) },
          });
        })
        .catch(async (error) => {
          const toast = await toastController.create({
            message: this.translate(error.response.data.message),
            position: "bottom",
            duration: 3000,
            color: "danger",
          });
          await toast.present();
        });
    },
  },
};
</script>

<style>
</style>